import { toast } from "react-toastify"
import api from "../../store/api"
import { useProfile } from "../../store/profile.state"
import Typography from "../Typography/index"
import { useEffect, useState } from "react"

const NavBar = () => {
  const { role, username } = useProfile()
  const [hide, setHide] = useState<boolean>(false)

  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        setHide(false)
      })
    }
  }, [hide])

  const { fetchFn: uploadAvatar } = api.auth.useUploadAvatar()
  const { setProfilePhoto, profilePhoto } = useProfile()

  return (
    <div className="w-full px-4 py-3">
      <div className="flex justify-between items-center">
        <div className="flex flex-col items-center gap-1">
          <Typography
            tag={"span"}
            className={"font-bold texxt-center text-[20px] text-black"}
          >
            Welcome Back!
          </Typography>
          <Typography
            tag={"span"}
            className={"font-bold texxt-center text-sm text-gray-400"}
          >
            {new Date(Date.now()).toDateString()}
          </Typography>
        </div>
        <div className="flex gap-x-2 relative">
          {!hide ? (
            <img
              src={profilePhoto ?? ""}
              className="h-12 w-12 rounded-full "
              alt=""
            />
          ) : null}
          <input
            type="file"
            className="absolute top-0 left-0 h-12 w-12 hover:cursor-pointer opacity-0"
            onChange={async (e) => {
              const file = e.target.files?.[0]
              if (!file) return

              const formData = new FormData()
              formData.append("image", file)
              const upload = await uploadAvatar({
                data: formData,
              })

              const { isSuccess, isError, error, data } = upload.unwrap()

              try {
                if (isSuccess) {
                  toast.success("Avatar uploaded successfully!")
                  console.log(data)
                  setProfilePhoto(data?.profilePicture?.url ?? "")
                  setHide(true)
                }
              } catch (err) {
                toast.error(
                  error?.response?.data?.message ?? "Something went wrong!"
                )
              }
            }}
          />
          <div className="flex flex-col justify-around">
            <Typography tag="span" className="font-semibold">
              {username}
            </Typography>
            <Typography tag="small" className="text-gray-400 font-semibold">
              {role}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBar
