import OuterWindow from "./OuterWindow";
import ShiftCard from "./ShiftCard";
import { AnimatePresence, motion } from "framer-motion";
import { InfinitySpin } from "react-loader-spinner";
import { sortShifts, useGetShiftsSingle } from "../../hooks/useGetShifts";
import {
  useGetDoctorsFilterQuery,
  useGetDoctorsSuggestionQuery,
} from "../../store/rosterApi";
import { useEstimationContext } from "../../hooks/useEstimationContext";
import { formatDisplayDate } from "../../utils/formatTime";
import { usePrefetchContext } from "../../hooks/usePrefetchContext";
import { Shift } from "../../interface/shift";

type SecondaryMonitorProps = {
  prevDate: Date;
  className?: string;
};

const SecondaryMonitor = ({
  prevDate,
  className,
}: SecondaryMonitorProps) => {
  const {
    data: shiftData,
    isLoading: isShiftsLoading,
    isFetching: isShiftsFetching,
    refetch: refetchShifts,
  } = useGetShiftsSingle({ activeDate: prevDate });

  const { editShiftId, doctorFilterSeniority } = useEstimationContext();

  const { refetch: refetchSuggestedDoctors } = useGetDoctorsSuggestionQuery(
    { shiftId: editShiftId },
    { skip: !editShiftId },
  );

  const { refetch: refetchFilterDoctors } = useGetDoctorsFilterQuery(
    {
      shiftId: editShiftId,
      seniority: doctorFilterSeniority,
    },
    { skip: !editShiftId },
  );

  const { triggerDoctorPrefetch, triggerStatsPrefetch } = usePrefetchContext();

  const refetchDoctors = async () => {
    await Promise.all([refetchSuggestedDoctors(), refetchFilterDoctors()]);
    triggerDoctorPrefetch();
    triggerStatsPrefetch();
  };

  return (
    <>
      <OuterWindow
        className={`h-full ${className}`}
        title={formatDisplayDate(prevDate)}
        headerClassName="bg-yellow2"
        bodyClass="flex flex-col gap-y-3 flex-grow overflow-auto"
        titleClassName="transition duration-500 "
        bodyChildNode={
          !(isShiftsLoading || (!shiftData && isShiftsFetching)) ? (
            shiftData && shiftData.length > 0 ? (
              <>
                {[...shiftData].sort(sortShifts).map(
                  (shift: Shift) => (
                    <ShiftCard
                      isFetching={isShiftsFetching}
                      refetchShifts={async () => {
                        await refetchShifts();
                      }}
                      refetchDoctors={refetchDoctors}
                      type={shift.type}
                      key={shift._id}
                      shiftId={shift._id}
                      time={shift.time}
                      slots={shift.slots}
                      windowType={"secondary"}
                    />
                  ),
                ) ?? []}
              </>
            ) : (
                <motion.div
                  key="noDataTransition"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.8 }}
                  className="font-medium flex justify-center items-center h-[210px]"
                >
                  No Shifts Added
                </motion.div>
            )
          ) : (
            <div className="m-auto col-span-2">
              <InfinitySpin width="200" color="#67823A" />
            </div>
          )
        }
      />
    </>
  );
};

export default SecondaryMonitor;
