import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import Fullscreen from "react-fullscreen-crossbrowser";
import { FiMinimize, FiMaximize } from "react-icons/fi";
import { DateTime } from "luxon";

import SideBarLayout from "../../layouts/Sidebar";
import { EstimationContextProvider } from "../../context/EstimationContext";
import { getDateFromHash } from "../../utils/getDateFromHash";
import DateScroll from "../../components/Estimation/DateScroll";
import SecondaryMonitor from "../../components/Estimation/SecondaryMonitor";
import PrimaryMonitor from "../../components/Estimation/PrimaryMonitor";
import { TopBar } from "../../components/NavBar/TopBarEstimation";
import ResponsiveWindow from "../../components/Estimation/ResponsiveWindow";
import DoctorFilterWindow from "../../components/Estimation/DoctorFilterWindow";
import { PrefetchContextProvider } from "../../context/PrefetchContext";
import { TIME_ZONE } from "../../constants";

const Estimation: FC = () => {
  const { dateHash } = useParams<{ dateHash: string }>();
  const [isFullscreenEnabled, setIsFullScreenEnableed] =
    useState<boolean>(false);

  if (!dateHash) {
    return <>Invalid (No Date Hash)</>;
  }

  const activeDate = getDateFromHash(dateHash);
  const nextDate = DateTime.fromJSDate(getDateFromHash(dateHash))
    .setZone(TIME_ZONE)
    .plus({ day: 1 })
    .toJSDate();
  const prevDate = DateTime.fromJSDate(getDateFromHash(dateHash))
    .setZone(TIME_ZONE)
    .plus({ day: -1 })
    .toJSDate();

  return (
    <Fullscreen
      enabled={isFullscreenEnabled}
      onChange={(isFullscreenEnabled: boolean) =>
        setIsFullScreenEnableed(isFullscreenEnabled)
      }
    >
      <PrefetchContextProvider
        activeDate={activeDate}
        nextDate={nextDate}
        prevDate={prevDate}
      >
        <EstimationContextProvider>
          <div className="flex max-h-screen h-screen bg-[#FEFDF8]">
            <SideBarLayout type="estimation">
              <div className="pt-2">
                <TopBar type="estimation" />
              </div>
              <button
                className="absolute right-10 text-secondary top-4 z-[999]"
                onClick={() => setIsFullScreenEnableed(!isFullscreenEnabled)}
              >
                {isFullscreenEnabled ? (
                  <FiMinimize className="w-7 h-7" />
                ) : (
                  <FiMaximize className="w-7 h-7" />
                )}
              </button>
              <div className="grid grid-rows-4 mt-11 grid-cols-10 flex-grow px-10 py-10">
                <div className="col-span-6 row-span-2 flex">
                  <DateScroll
                    className="m-auto"
                    activeDate={activeDate}
                    nextDate={nextDate}
                    prevDate={prevDate}
                  ></DateScroll>
                </div>
                <div className="col-span-4 row-span-4 pl-10">
                  <div className="grid grid-cols-1 grid-rows-4 h-full p-0.5 gap-y-4">
                    <div className="col-span-1 row-span-2 py-5">
                      <ResponsiveWindow
                        activeDate={activeDate}
                        nextDate={nextDate}
                        prevDate={prevDate}
                      />
                    </div>
                    <div className="col-span-1 row-span-2">
                      <DoctorFilterWindow activeDate={activeDate} />
                    </div>
                  </div>
                </div>
                <div className="col-span-6 row-span-2 p-1 ">
                  <div className="grid grid-cols-3 gap-4 h-full">
                    <div className="col-span-1 h-full overflow-auto">
                      <SecondaryMonitor
                        className="w-full flex flex-col"
                        prevDate={prevDate}
                      />
                    </div>
                    <div className="col-span-2 flex flex-col h-full overflow-auto">
                      <PrimaryMonitor
                        className="w-full flex flex-col"
                        bodyClass="flex-grow overflow-auto"
                        activeDate={activeDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SideBarLayout>
          </div>
        </EstimationContextProvider>
      </PrefetchContextProvider>
    </Fullscreen>
  );
};

export default Estimation;
