import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const rosterApi = createApi({
  reducerPath: "rosterApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  endpoints: (builder) => ({
    getDoctorsSuggestion: builder.query<any, any>({
      query: ({ shiftId }) => ({
        url: `roster/${shiftId}/generate-suggestions`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getDoctorsFilter: builder.query<any, any>({
      query: ({ shiftId, seniority }) => ({
        url: `roster/generate-doctors?seniority=${seniority}${
          shiftId ? `&shiftId=${shiftId}` : ""
        }`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getDoctorStats: builder.query<any, any>({
      query: ({ month, year, seniority }) => ({
        url: `roster/generate-doctor-stats?month=${month}&year=${year}&seniority=${seniority}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    assignRandomn: builder.mutation<any, any>({
      query: ({ shiftId }) => ({
        url: `roster/${shiftId}/assign-random`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    createSchedule: builder.mutation<any, any>({
      query: ({ shiftId, doctorId }) => ({
        url: `roster/assign`,
        method: "post",
        body: { shiftId, doctorId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteSchedule: builder.mutation<any, any>({
      query: ({ scheduleId }) => ({
        url: `roster/remove`,
        method: "DELETE",
        body: { scheduleId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    openRequestDays: builder.mutation<
      any,
      {
        locationId: string;
        dates: string[];
        deadline?: string;
        seniority: number;
        isFinalized: boolean;
      }
    >({
      query: (body) => {
        const { locationId, ...payload } = body;
        return {
          url: `/location/${locationId}/open-request`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
          body: payload,
        };
      },
    }),
    autoAssignment: builder.mutation<
      any,
      {
        location: string;
        month: number;
        year: number;
        seniority: number;
      }
    >({
      query: ({ location, month, year, seniority }) => ({
        url: `/roster/auto-assign`,
        method: "POST",
        body: { location, month, year, seniority },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
        timeout: 500 * 1000,
      }),
    }),
    importDoctors: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: `/doctor/import`,
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    importShifts: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: `/roster/import`,
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    exportDoctors: builder.mutation<any, void>({
      query: () => ({
        url: `/doctor/export`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
        responseHandler: async (response: any) => {
          const body = await response.json()
          const url = window.URL.createObjectURL(
            new Blob([body.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }),
          );
          const tempLinkElem = document.createElement("a");
          tempLinkElem.href = url;
          tempLinkElem.download = body.fileName;
          tempLinkElem.click();
          tempLinkElem.remove();
        },
        cache: "no-cache",
      }),
    }),
    sendEmails: builder.mutation<
      any,
      {
        emails: string[];
      }
    >({
      query: ({ emails }) => ({
        url: `/doctor/send-emails`,
        method: "POST",
        body: { emails },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getDoctorShifts: builder.query<any, any>({
      query: ({ doctorId, month, year, locationId }) => ({
        url: `/roster/doctor?doctorId=${doctorId}&month=${month}&year=${year}&locationId=${locationId}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    changePassword: builder.mutation<
      { message: string },
      {
        currentPassword: string;
        newPassword: string;
      }
    >({
      query: ({ currentPassword, newPassword }) => ({
        url: `/auth/change-password`,
        method: "POST",
        body: { currentPassword, newPassword },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetDoctorsFilterQuery,
  useGetDoctorsSuggestionQuery,
  useGetDoctorShiftsQuery,
  useGetDoctorStatsQuery,
  useCreateScheduleMutation,
  useDeleteScheduleMutation,
  useImportDoctorsMutation,
  useOpenRequestDaysMutation,
  useChangePasswordMutation,
  useAutoAssignmentMutation,
  useImportShiftsMutation,
  useExportDoctorsMutation,
  useSendEmailsMutation,
  useAssignRandomnMutation,
  usePrefetch,
} = rosterApi;
