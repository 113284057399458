import { FC, PropsWithChildren } from "react";
import SideBar from "../components/SideBar";
import { useModal } from "../hooks/useModal";
import { useOutsideClickRef } from "rooks";

const SideBarLayout: FC<
  PropsWithChildren<{
    type?: "general" | "estimation";
    isCalendarLoading?: boolean;
  }>
> = ({ children, isCalendarLoading = false, type = "general" }) => {
  const { modalsJsx, closeModal, useOutsideClick, blur } = useModal();
  const [modalRef] = useOutsideClickRef(() => {
    closeModal();
  }, useOutsideClick);

  return (
    <div className="flex max-h-screen overflow-auto w-screen relative">
      <SideBar type={type} isCalendarLoading={isCalendarLoading} />
      {children}
      {modalsJsx.map((jsx, i) => (
        <div
          key={i}
          className={`flex flex-col h-screen w-full justify-center z-[999] items-center absolute top-0 left-0 bg-black bg-opacity-50 ${
            blur ? "backdrop-blur-sm" : ""
          }`}
          ref={(node) => {
            if (node) {
              node.style.overflowY = "auto";
            }
          }}
          style={{
            overflowY: "auto",
          }}
        >
          <div ref={modalRef}>{jsx}</div>
        </div>
      ))}
    </div>
  );
};

export default SideBarLayout;
