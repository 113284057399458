import React, { useState } from "react";
import { DateTime } from "luxon";
import { HiDotsHorizontal, HiCheckCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoMdLock } from "react-icons/io";
import { useDispatch } from "react-redux";

import ContextMenu from "../ContextMenu";
import Typography from "../Typography";
import { useModal } from "../../hooks/useModal";
import RoundedCalendar from "./RoundedCalander";
import api from "../../store/api";
import { useSeniority } from "../../store/seniority.state";
import { useLocation } from "../../store/location.store";
import EstimatedDoctorSummary from "../Modals/EstimatedDoctorSummary";
import { TIME_ZONE } from "../../constants";
import { formatDateAPI } from "../../utils/formatTime";
import { useGetCalendarQuery } from "../../store/locationApi";
import { shiftsApi } from "../../store/shiftsApi";

interface CalenderCardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "primary" | "purple" | "";
  isEstimated?: boolean;
  day: string;
  date: Date;
  monthName: string;
  isDisabled?: boolean;
  skeleton?: boolean;
  onClick?: () => void;
  summary?: object;
  className?: string;
  others?: React.ReactNode;
  showContextMenu?: boolean;
  isFinalized?: boolean;
}

const CalenderCard = (props: CalenderCardProps) => {
  const {
    isEstimated = false,
    isFinalized = false,
    day,
    date,
    variant = "primary",
    isDisabled = false,
    monthName,
    summary,
    onClick,
    className,
    skeleton = false,
    others,
    showContextMenu = true,
  } = props;

  const variantClasses: Record<typeof variant, string> = {
    primary: "bg-white shadow-lg",
    purple: "bg-purple2 shadow",
    "": "bg-white shadow-lg",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { openModal, closeModal } = useModal();
  const [copyingFromDate] = useState<Date>(date);

  const { activeId: activeSeniorityId } = useSeniority();
  const { activeId: activeLocationId } = useLocation();

  const { fetchFn: copySettings } = api.location.useCopySettings();

  const { refetch: refetchCalendar, data: calendarData } = useGetCalendarQuery(
    {
      locationId: activeLocationId,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      seniority: activeSeniorityId,
    },
    { skip: !activeLocationId },
  );

  if (skeleton) {
    return (
      <div
        className={`rounded-b-2xl flex flex-col gap-4 rounded-tr-2xl px-4 py-4 shadow-lg ${className}}`}
      >
        <div className="flex justify-between items-center">
          <div className="font-semibold text-base text-gray-300 animate-pulse text-transparent bg-gray-300 rounded">
            <pre>&nbsp;&nbsp;&nbsp;&nbsp;</pre>
          </div>
          <button
            className="z-10"
            disabled={true}
          >
            <HiDotsHorizontal
              className="text-transparent animate-pulse w-8 h-4 bg-gray-300 rounded"
              size={25}
            />
          </button>
        </div>
        <div className="flex justify-between items-center text-secondary font-semibold text-2xl">
          <div className="animate-pulse text-transparent bg-gray-300 rounded">
            <pre>&nbsp;&nbsp;&nbsp;&nbsp;</pre>
          </div>

          <button className="animate-pulse text-transparent bg-gray-300 rounded-full">
            <HiCheckCircle className="text-transparent" size={28} />
          </button>
        </div>
      </div>
    );
  }
  return (
    <div
      onClick={onClick}
      className={`rounded-b-2xl cursor-pointer flex flex-col gap-4 rounded-tr-2xl px-4 py-4 ${className} ${
        variantClasses[variant]
      } ${isDisabled ? "!bg-[#D1DBAF99] shadow-none" : ""}`}
    >
      <div className="flex justify-between items-center">
        <div className="font-semibold flex gap-x-2 items-center text-base text-black3">
          {day}
          {isFinalized ? <IoMdLock className="h-4 w-4" /> : null}
        </div>

        {showContextMenu ? (
          <ContextMenu
            disabled={isDisabled}
            menu={
              <div className="bg-white rounded-lg flex flex-col divide-y-2">
                <button
                  className="hover:bg-slate-300 text-start p-3"
                  onClick={() => {
                    const dateHash = window.btoa(date.getTime().toString());
                    navigate(`/dashboard/${dateHash}`);
                  }}
                >
                  <Typography
                    tag="span"
                    className="whitespace-nowrap p-3 font-semibold"
                  >
                    Estimate manpower required
                  </Typography>
                </button>
                {summary ? (
                  <button
                    className="hover:bg-slate-300 text-start p-3"
                    onClick={() => {
                      const closedDates = calendarData.calendar
                        ?.filter((c: any) => c.isClosed)
                        .map((c: any) => new Date(c.date));
                      openModal(
                        <RoundedCalendar
                          onCancel={() => closeModal()}
                          disableYellowDates={true}
                          initial={{
                            yellowDates: [copyingFromDate],
                            disabledDates: isDisabled
                              ? [
                                  date,
                                  ...closedDates.map((cd: string) =>
                                    DateTime.fromISO(cd)
                                      .setZone(TIME_ZONE)
                                      .toJSDate(),
                                  ),
                                ]
                              : [
                                  ...closedDates.map((cd: string) =>
                                    DateTime.fromISO(cd)
                                      .setZone(TIME_ZONE)
                                      .toJSDate(),
                                  ),
                                ],
                          }}
                          showFooter={true}
                          onSubmit={async (dates) => {
                            const copy = await copySettings({
                              data: {
                                locationId: activeLocationId,
                                seniority: activeSeniorityId,
                                from: formatDateAPI(copyingFromDate),
                                to: dates.map((d) => formatDateAPI(d)),
                              },
                            });

                            const { isSuccess, isError, error } = copy.unwrap();

                            if (isSuccess) {
                              dispatch(shiftsApi.util.resetApiState())
                              toast.success("Settings copied successfully");
                              refetchCalendar();
                              closeModal();
                              return;
                            }

                            if (isError) {
                              toast.error(error?.response?.data?.message);
                            }
                            closeModal();
                          }}
                        />,
                      );
                    }}
                  >
                    <Typography
                      tag="span"
                      className="whitespace-nowrap p-3 font-semibold"
                    >
                      Copy My Settings
                    </Typography>
                  </button>
                ) : null}
              </div>
            }
          >
            <button>
              <HiDotsHorizontal className="text-black3" size={25} />
            </button>
          </ContextMenu>
        ) : null}
      </div>
      <div className="flex justify-between items-center text-secondary font-semibold text-2xl">
        <div className="">{monthName}</div>
        {isEstimated ? (
          <button
            onClick={(e) => {
              console.log("tick click");
              e.stopPropagation();
              openModal(<EstimatedDoctorSummary initialDate={date} />, {
                blur: true,
              });
            }}
          >
            <HiCheckCircle
              className={`${
                (summary as any)?.estimatedPercentage < 100
                  ? "text-black3"
                  : "text-green-600"
              }`}
              size={28}
            />
          </button>
        ) : null}
      </div>
      {others ? others : null}
    </div>
  );
};

export default CalenderCard;
