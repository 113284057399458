import { Dispatch, SetStateAction, useMemo, useState } from "react";
import NumberSlider from "./NumberScroll";

type IosTimePickerProps = {
  time: string;
  setTime: Dispatch<SetStateAction<string>>;
};

const parseTime = (
  time: string
): { hour: number; minute: number; timeType: "am" | "pm" } => {
  const [hour, secondPart] = time.split(":");
  const [minute, timeType] = secondPart.split(" ");
  return {
    hour: Number(hour),
    minute: Number(minute),
    timeType: timeType.toLowerCase() as "am" | "pm",
  };
};

export const IosTimePicker = ({ time, setTime }: IosTimePickerProps) => {
  const [hour, setHour] = useState<number>(parseTime(time).hour);
  const [minute, setMinute] = useState<number>(parseTime(time).minute);
  const [timeType, setTimeType] = useState<"am" | "pm">(
    parseTime(time).timeType
  );

  const currentTime = useMemo(
    () =>
      `${hour}:${minute.toString().padStart(2, "0")} ${timeType.toUpperCase()}`,
    [hour, minute, timeType]
  );

  if(time !== currentTime){
    setTime(currentTime)
  }

  return (
    <div className="w-fit gap-1 flex items-center justify-between border-2 border-secondary rounded-lg px-2">
      <NumberSlider
        id={"hour-slider"}
        min={1}
        max={12}
        number={hour}
        setNumber={setHour}
      />
      <div className="flex justify-center items-center w-3 text-black font-bold text-sm">
        :
      </div>
      <NumberSlider
        id={"min-slider"}
        min={0}
        max={59}
        number={minute}
        setNumber={setMinute}
      />
      <button
        onClick={() => setTimeType("am")}
        className={`${
          timeType == "am" ? "bg-secondary text-white" : "bg-gray6 text-black3"
        } h-fit rounded-[4px] font-medium text-[8px] px-1 py-[3px]`}
      >
        AM
      </button>
      <button
        onClick={() => setTimeType("pm")}
        className={`${
          timeType == "pm" ? "bg-[#84ACE2] text-white" : "bg-gray6 text-black3 "
        } h-fit rounded-[4px] font-medium text-[8px]  px-1 py-[3px]`}
      >
        PM
      </button>
    </div>
  );
};
