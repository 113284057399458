import { useCallback, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import DoctorSearch from "./DoctorSearch";
import AllFilter from "../Icons/AllFilter";
import RoundedSeniorityTag from "../Icons/RoundedSeniorityTag";
import RoundedTags from "../Icons/RoundedTags";
import TagPill from "../TagPill";
import HorizontalScrollContainer from "../HorizontalScrollContainer";
import TopToBottomArrow from "../Icons/TopToBottomArrow";
import DoctorCard from "../Cards/DoctorCard.tsx";
import RoundedSeniority from "../Icons/RoundedSeniority";
import {
  useGetDoctorsFilterQuery,
  useGetDoctorsSuggestionQuery,
} from "../../store/rosterApi";
import { useEstimationContext } from "../../hooks/useEstimationContext";
import { InfinitySpin } from "react-loader-spinner";
import { useGetShiftsSingle } from "../../hooks/useGetShifts";
import { useGetGroupsQuery } from "../../store/groupApi.ts";
import { usePrefetchContext } from "../../hooks/usePrefetchContext.tsx";
import { useGetTagsQuery } from "../../store/doctorApi.ts";

type DoctorFilterWindowProps = {
  activeDate: Date;
};

const iconTransitionClasses = "transition-opacity duration-700 ease-in-out";

const pillActiveBgColors = {
  "3": "bg-[#DF9C28] ",
  "2": "bg-[#84ACE2] ",
  "1": "bg-[#E57041] ",
};

const DoctorFilterWindow = ({ activeDate }: DoctorFilterWindowProps) => {
  const [showIcons, setShowIcons] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const {
    editShiftId,
    dataDoctorId,
    doctorFilterSeniority,
    setDoctorFilterSeniority,
    setActiveTab,
    activeTab,
    setResponsiveWindow,
    setDataDoctorId,
    setMonitorActiveId,
  } = useEstimationContext();

  const { refetch: refetchShifts } = useGetShiftsSingle({
    activeDate,
  });

  const {
    data: doctorsData,
    isLoading: isDoctorsLoading,
    isFetching: isDoctorsFetching,
    refetch: refetchDoctors,
  } = useGetDoctorsFilterQuery({
    shiftId: editShiftId,
    seniority: activeTab === "tags" ? 4 : doctorFilterSeniority,
  });

  const {
    data: suggestedDoctorsData,
    isLoading: isDoctorsSuggestionsLoading,
    isFetching: isDoctorsSuggestionsFetching,
    refetch: refetchSuggestedDoctors,
  } = useGetDoctorsSuggestionQuery(
    { shiftId: editShiftId },
    { skip: !editShiftId },
  );

  const {
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
    data: groups,
  } = useGetGroupsQuery({ seniority: doctorFilterSeniority });

  const { triggerDoctorPrefetch, triggerStatsPrefetch } = usePrefetchContext();

  const { data: tags, isLoading: isTagsLoading } = useGetTagsQuery({});

  const [activeTags, setActiveTags] = useState<string[]>([]);
  const [activeGroups, setActiveGroups] = useState<any[]>([]);
  const [activeSubGroups, setActiveSubGroups] = useState<any[]>([]);

  function handleActiveTags(val: string) {
    if (activeTags.includes(val)) {
      setActiveTags(activeTags.filter((tag) => tag !== val));
    } else {
      setActiveTags([...activeTags, val]);
    }
  }

  function handleActiveGroups(selectedGroup: any) {
    const isGroupActive = activeGroups.some(
      (group) => group.id === selectedGroup.id,
    );
    if (isGroupActive) {
      setActiveGroups(
        activeGroups.filter((group) => group.id !== selectedGroup.id),
      );
      setActiveSubGroups([]);
    } else {
      setActiveSubGroups([]);
      setActiveGroups([selectedGroup]);
    }
  }
  function handleActiveSubGroups(selectedSubGroup: any) {
    const isSubGroupActive = activeSubGroups.some(
      (subGroup) => subGroup.id === selectedSubGroup.id,
    );
    if (isSubGroupActive) {
      setActiveSubGroups(
        activeSubGroups.filter(
          (subGroup) => subGroup.id !== selectedSubGroup.id,
        ),
      );
    } else {
      setActiveSubGroups([...activeSubGroups, selectedSubGroup]);
    }
  }
  const handleDoctorSearchFocus = () => {
    setShowIcons(false);
  };

  const handleDoctorSearchBlur = () => {
    setShowIcons(true);
  };

  const doctorFilerFn = useCallback(
    (doctor: {
      subGroups: any;
      tags: any;
      groups: any;
      user: { name: string };
    }) => {
      const searchBoolean =
        searchQuery === "" ||
        doctor.user.name.toLowerCase().startsWith(searchQuery.toLowerCase());

      if (activeTab === "suggested") {
        return searchBoolean;
      }

      if (activeTab === "seniority") {
        const groupBoolean =
          activeGroups.length === 0 ||
          activeGroups.find((group) => {
            return doctor.groups[0] && group._id === doctor.groups[0]._id;
          });

        const subGroupBoolean =
          activeSubGroups.length === 0 ||
          activeSubGroups.find((subGroup) => {
            return (
              doctor.subGroups[0] && subGroup._id === doctor.subGroups[0]._id
            );
          });
        return searchBoolean && groupBoolean && subGroupBoolean;
      } else {
        const tagsBoolean =
          activeTags.length === 0 ||
          activeTags.some((tag) =>
            doctor.tags.find(
              (tagObj: { value: string }) => tagObj.value === tag,
            ),
          );

        if (activeTab === "tags") {
          return searchBoolean && tagsBoolean;
        }
      }
    },
    [activeGroups, activeTab, activeSubGroups, activeTags, searchQuery],
  );

  const displayType = (() => {
    if (activeTab !== "suggested") {
      return isDoctorsLoading
        ? "loading"
        : doctorsData && doctorsData.filter(doctorFilerFn).length > 0
        ? "displayData"
        : "noData";
    } else {
      return editShiftId
        ? isDoctorsSuggestionsLoading
          ? "loading"
          : suggestedDoctorsData &&
            suggestedDoctorsData.filter(doctorFilerFn).length > 0
          ? "displayData"
          : "noData"
        : "selectShift";
    }
  })();

  return (
    <div className="flex flex-col bg-[#FEFDF8] h-full rounded-xl max-w-full">
      <div className={`flex items-center gap-x-2`}>
        <DoctorSearch
          className={`flex-grow transition-all duration-700 ease-in-out`}
          onFocus={handleDoctorSearchFocus}
          onBlur={handleDoctorSearchBlur}
          searchValue={searchQuery}
          setSearchValue={setSearchQuery}
        />
        <div
          className={`flex items-center ${showIcons ? "gap-x-2" : "gap-x-0"}`}
        >
          <div
            className={`${iconTransitionClasses} ${
              !(showIcons || activeTab === "suggested")
                ? "opacity-0"
                : "opacity-100"
            }`}
          >
            {(showIcons || activeTab === "suggested") && (
              <AllFilter
                className="cursor-pointer"
                fill={activeTab === "suggested" ? "fill-secondary" : ""}
                iconColor={activeTab === "suggested" ? "fill-white" : ""}
                onClick={() => {
                  if (activeTab !== "suggested") {
                    setActiveTab("suggested");
                    setActiveGroups([]);
                    setActiveSubGroups([]);
                    setActiveTags([]);
                  }
                }}
              />
            )}
          </div>
          <div
            className={`${iconTransitionClasses} ${
              !(showIcons || activeTab === "seniority")
                ? "opacity-0"
                : "opacity-100"
            }`}
          >
            {(showIcons || activeTab === "seniority") && (
              <RoundedSeniority
                className="cursor-pointer"
                fill={activeTab === "seniority" ? "fill-secondary" : ""}
                iconColor={activeTab === "seniority" ? "fill-white" : ""}
                onClick={() => {
                  if (activeTab !== "seniority") {
                    setActiveTab("seniority");
                    setActiveGroups([]);
                    setActiveSubGroups([]);
                    setActiveTags([]);
                  }
                }}
              />
            )}
          </div>
          <div
            className={`${iconTransitionClasses} ${
              !(showIcons || activeTab === "tags") ? "opacity-0" : "opacity-100"
            }`}
          >
            {(showIcons || activeTab === "tags") && (
              <RoundedTags
                className="cursor-pointer"
                fill={activeTab === "tags" ? "fill-white" : ""}
                fillbg={activeTab === "tags" ? "fill-secondary" : ""}
                onClick={() => {
                  if (activeTab !== "tags") {
                    setActiveTab("tags");
                    setActiveGroups([]);
                    setActiveSubGroups([]);
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {activeTab === "seniority" && (
          <motion.div
            key="seniority-buttons"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            transition={{ type: "interia" }}
            className={`flex items-center mt-3 ml-3 gap-x-2`}
          >
            <div className={`${iconTransitionClasses}`}>
              <RoundedSeniorityTag
                className="cursor-pointer"
                fill={doctorFilterSeniority === 1 ? "senior" : "white"}
                stroke={"senior"}
                onClick={() => {
                  setDoctorFilterSeniority(1);
                  setActiveGroups([]);
                  setActiveSubGroups([]);
                }}
              />
            </div>
            <div className={`${iconTransitionClasses}`}>
              <RoundedSeniorityTag
                className="cursor-pointer"
                fill={doctorFilterSeniority === 2 ? "midlevel" : "white"}
                stroke={"midlevel"}
                onClick={() => {
                  setDoctorFilterSeniority(2);
                  setActiveGroups([]);
                  setActiveSubGroups([]);
                }}
              />
            </div>
            <div className={`${iconTransitionClasses}`}>
              <RoundedSeniorityTag
                className="cursor-pointer"
                fill={doctorFilterSeniority === 3 ? "junior" : "white"}
                stroke={"junior"}
                onClick={() => {
                  setDoctorFilterSeniority(3);
                  setActiveGroups([]);
                  setActiveSubGroups([]);
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="bg-gray6 h-full flex-grow overflow-auto rounded-xl mt-4 pr-3">
        <div>
          {activeTab === "tags" && (
            <HorizontalScrollContainer
              isLoading={isTagsLoading}
              className="p-3"
            >
              {tags?.map((tag: { value: string }, i: number) => (
                <TagPill
                  key={i}
                  name={tag.value}
                  className={"w-fit"}
                  activeBgColor={"bg-secondary"}
                  isActive={activeTags.includes(tag.value)}
                  onClick={() => handleActiveTags(tag.value)}
                />
              ))}
            </HorizontalScrollContainer>
          )}
          {activeTab === "seniority" && (
            <HorizontalScrollContainer
              isLoading={isGroupsLoading}
              className="p-3"
            >
              {groups?.map(
                (group: { id: string; title: string; subGroups: any[] }) => (
                  <TagPill
                    key={group.id}
                    name={group.title}
                    className={"w-fit flex gap-x-4 items-center"}
                    isActive={activeGroups.some(
                      (activeGroup) => activeGroup.id === group.id,
                    )}
                    activeBgColor={
                      pillActiveBgColors[
                        String(doctorFilterSeniority) as "1" | "2" | "3"
                      ]
                    }
                    onClick={() => handleActiveGroups(group)}
                    children={
                      group.subGroups.length > 0 && (
                        <TopToBottomArrow
                          fill={
                            activeGroups.some(
                              (activeGroup) => activeGroup.id === group.id,
                            )
                              ? ""
                              : "fill-black3"
                          }
                        />
                      )
                    }
                  />
                ),
              )}
            </HorizontalScrollContainer>
          )}
          {activeTab === "seniority" && (
            <HorizontalScrollContainer className="px-3">
              {activeGroups.map((group) => {
                if (group.subGroups.length > 0) {
                  return group.subGroups.map((item: any) => (
                    <TagPill
                      key={item.id}
                      name={item.title}
                      className={"w-fit "}
                      activeBgColor={
                        pillActiveBgColors[
                          String(doctorFilterSeniority) as "1" | "2" | "3"
                        ]
                      }
                      isActive={activeSubGroups.some(
                        (activeSubGroup) => activeSubGroup.id === item.id,
                      )}
                      onClick={() => handleActiveSubGroups(item)}
                    />
                  ));
                }
                return null;
              })}
            </HorizontalScrollContainer>
          )}
        </div>
        {displayType === "loading" ? (
          <div className="flex justify-center items-center h-[50%]">
            <InfinitySpin width="200" color="#67823A" />
          </div>
        ) : displayType === "displayData" ? (
          <div className="grid grid-cols-2 gap-4 px-4 py-4 overflow-y-scroll">
            {(activeTab === "suggested" ? suggestedDoctorsData : doctorsData)
              .filter(doctorFilerFn)
              .map(
                (doctor: {
                  tags: any;
                  groups: any;
                  efficiencyScore: number;
                  experience: number;
                  canAssign: boolean;
                  _id: string;
                  user: {
                    email: string;
                    name: string;
                  };
                  availability: "warning" | "available" | "unavailable";
                  seniority: { id: number };
                }) => (
                  <DoctorCard
                    key={doctor._id}
                    type={
                      editShiftId ? "doctor-filter" : "doctor-filter-no-shift"
                    }
                    doctorId={doctor._id}
                    doctor={doctor}
                    name={doctor.user.name}
                    status={doctor.availability}
                    seniority={String(doctor.seniority.id) as "1" | "2" | "3"}
                    canAssign={doctor.canAssign}
                    isActive={doctor._id === dataDoctorId}
                    onClickHandler={() => {
                      if (dataDoctorId && dataDoctorId === doctor._id) {
                        setDataDoctorId(undefined);
                        setResponsiveWindow({
                          type: "dateView",
                          props: {},
                        });
                      } else {
                        setMonitorActiveId(undefined);
                        setDataDoctorId(doctor._id);
                        setResponsiveWindow({
                          type: "userData",
                          props: {
                            doctorId: doctor._id,
                            name: doctor.user.name,
                            email: doctor.user.email,
                            efficiency: doctor.efficiencyScore,
                            experience: doctor.experience,
                            groups: doctor.groups,
                            subGroups: doctor.groups,
                            tags: doctor.tags,
                          },
                        });
                      }
                    }}
                    refetchShifts={async () => {
                      await refetchShifts();
                      // triggerRefetchShifts()
                    }}
                    isFetching={
                      isDoctorsFetching || isDoctorsSuggestionsFetching
                    }
                    refetchDoctors={async () => {
                      await refetchDoctors();
                      await refetchSuggestedDoctors();
                      
                      triggerDoctorPrefetch();
                      triggerStatsPrefetch();
                    }}
                  />
                ),
              )}
          </div>
        ) : displayType === "noData" ? (
          <div className="flex justify-center items-center h-full font-medium">
            No Doctors Found
          </div>
        ) : (
          <div className="flex justify-center items-center h-full font-medium">
            Please Select a Shift
          </div>
        )}
      </div>
    </div>
  );
};

export default DoctorFilterWindow;
