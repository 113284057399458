import { FC } from "react";
import OuterWindow from "../OuterWindow";
import EditUnderline from "../../Icons/EditUnderline";
import { Minus } from "../../Icons/Minus";
import { Add } from "../../Icons/Add";
import { AnimatePresence, motion } from "framer-motion";
import Delete from "../../Icons/Delete";
import { TIME_ZONE } from "../../../constants";
import { formatDateLong, formatTime } from "../../../utils/formatTime";
import { useEstimationContext } from "../../../hooks/useEstimationContext";
import { useUpdateShiftMutation } from "../../../store/shiftsApi";
import { useDeleteScheduleMutation } from "../../../store/rosterApi";
import useHandleSuccessErrors from "../../../hooks/handleSuccessErrors";
import { InfinitySpin } from "react-loader-spinner";
import { useGetShifts } from "../../../hooks/useGetShifts";
import { DateTime } from "luxon";

type ShiftTimeProps = {
  shiftId: string;
  activeDate: Date;
  nextDate: Date;
  prevDate: Date;
};

const ShiftViewWindow = ({
  shiftId,
  activeDate,
  nextDate,
  prevDate,
}: ShiftTimeProps) => {

  const { setResponsiveWindow, setMonitorActiveId } = useEstimationContext();

  // change to single
  const {
    data: shiftData,
    isLoading: isShiftsLoading,
    isFetching: isShiftsFetching,
    refetch: refetchShifts,
  } = useGetShifts({
    activeDate,
    nextDate,
    prevDate,
  });

  const shift = shiftData?.flat()?.find(
    (shift: { _id: string }) => shift._id === shiftId,
  );

  const [
    updateShift,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateShiftMutation();

  const [
    deleteSchedule,
    {
      isLoading: isDeleteScheduleLoading,
      isSuccess: isDeleteScheduleSuccess,
      isError: isDeleteScheduleError,
      error: scheduleError,
    },
  ] = useDeleteScheduleMutation();

  useHandleSuccessErrors({
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    error: updateError,
    successMessage: "Shift update successfull",
    successFunction: async () => {
      await refetchShifts();
    },
  });

  useHandleSuccessErrors({
    isSuccess: isDeleteScheduleSuccess,
    isError: isDeleteScheduleError,
    error: scheduleError,
    successMessage: "Schedule deleted successfully",
    successFunction: async () => {
      await refetchShifts();
    },
  });

  return (
    <OuterWindow
      className="bg-secondary text-white  rounded-xl flex flex-col h-full"
      headerClassName={`!justify-between px-4 gap-4 ${
        isShiftsLoading ? "animate-pulseFast" : ""
      }`}
      title={
        !isShiftsLoading && !isShiftsFetching
          ? formatDateLong(shift.date)
          : "Loading..."
      }
      bodyClass="flex flex-col h-full !pt-0 !bg-white flex-grow"
      headerChildNode={
        !isShiftsLoading && !isShiftsFetching ? (
          <>
            <div className="flex items-center gap-2">
              <ShiftTime
                label={"Start Time"}
                shiftTime={formatTime(shift.time.from)}
              />
              <ShiftTime
                label={"End Time"}
                shiftTime={formatTime(shift.time.to)}
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setMonitorActiveId(undefined);
                setResponsiveWindow({
                  type: "editShift",
                  props: {
                    shiftId,
                    date: DateTime.fromISO(shift.date)
                      .setZone(TIME_ZONE)
                      .toJSDate(),
                    time: shift.time,
                    slotsCountProp: shift.slots.length,
                    slotsFilled: shift.slots.filter(
                      (slot: { schedule: any }) => slot.schedule,
                    ).length,
                  },
                });
              }}
            >
              <EditUnderline />
            </div>
          </>
        ) : (
          <></>
        )
      }
      bodyChildNode={
        !isShiftsLoading && !isShiftsFetching ? (
          <>
            <div
              className={`flex-grow mb-4 text-secondary font-bold text-sm rounded-lg flex justify-center items-start ${
                isUpdateLoading || isDeleteScheduleLoading || isShiftsLoading
                  ? "animate-pulseFast"
                  : ""
              }`}
            >
              <ul className="w-full mx-auto grid grid-cols-2 gap-x-8 gap-y-2 pt-1.5 px-2">
                <AnimatePresence>
                  {shift?.slots.length === 0 ? (
                    <motion.li
                      key="noDataTransition"
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.2 }}
                      className={`pb-0.5 pt-1.5 border-x-0 border-t-0 border-b-[#BDBDBD] transition-opacity`}
                    >
                      <div className="font-medium text-sm p-1 text-black2 text-center">
                        No Data
                      </div>
                    </motion.li>
                  ) : null}
                  {shift?.slots.map(
                    (
                      slot: {
                        schedule: { doctor: { user: { name: any } }; _id: any };
                      },
                      index: number,
                    ) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2 }}
                        className={`pb-0.5 pt-1.5 cursor-pointer ${
                          index === shift?.slots.length - 1
                            ? ""
                            : "border-[0.4px] "
                        } border-x-0 border-t-0 border-b-[#E0E0E0] `}
                      >
                        {slot.schedule ? (
                          <div
                            className={`relative group font-medium text-sm ${
                              index == shift?.slots.length - 1 ? " pt-2" : "p-1"
                            } text-black2 text-center`}
                          >
                            {slot.schedule?.doctor?.user?.name ?? ""}
                            <div
                              className={`group-hover:block absolute hidden ${
                                index == shift?.slots.length - 1
                                  ? "bottom-0"
                                  : "bottom-1"
                              }  rounded  p-1 my-auto right-0  `}
                              onClick={async () =>
                                await deleteSchedule({
                                  scheduleId: slot.schedule._id,
                                })
                              }
                            >
                              <Delete
                                className="h-3.5 w-3.5"
                                stroke="stroke-pink1 transition duration-500"
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`font-medium text-sm ${
                              index == shift?.slots.length - 1 ? " pt-2" : "p-1"
                            } text-black2 text-center flex justify-center items-center`}
                          >
                            <Add stroke="stroke-black2" className="h-4 w-4" />
                          </div>
                        )}
                      </motion.li>
                    ),
                  )}
                </AnimatePresence>
              </ul>
            </div>
            <div className=" flex items-center mt-auto text-xs justify-between bg-secondary h-8 rounded-lg px-4 ">
              <button
                className="text-white p-0.5 cursor-pointer group hover:bg-white rounded-sm transition-colors duration-500"
                onClick={async () => {
                  if (
                    shift?.slots.length >
                    shift?.slots.filter(
                      (slot: { schedule: any }) => slot.schedule,
                    ).length
                  ) {
                    await updateShift({
                      shiftId,
                      totalDoctorsRequired: shift?.slots.length - 1,
                    });
                  }
                }}
              >
                <Minus
                  className="h-4 w-4"
                  stroke={`stroke-white group-hover:stroke-secondary transition duration-500`}
                />
              </button>
              <div className="my-auto font-medium">
                {shift?.slots.reduce(
                  (count: number, slot: { schedule: any }) => {
                    if (slot.schedule) {
                      return count + 1;
                    }
                    return count;
                  },
                  0,
                )}
                /{shift?.slots.length}
              </div>
              <button
                className="text-white p-0.5 cursor-pointer group hover:bg-white rounded-sm transition-colors duration-500"
                onClick={async () => {
                  if (shift?.slots.length < 8) {
                    await updateShift({
                      shiftId,
                      totalDoctorsRequired: shift?.slots.length + 1,
                    });
                  }
                }}
              >
                <Add
                  className="h-4 w-4"
                  stroke={`stroke-white group-hover:stroke-secondary transition duration-500`}
                />
              </button>
            </div>
          </>
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <InfinitySpin width="200" color="#67823A" />
          </div>
        )
      }
    />
  );
};
export default ShiftViewWindow;

export const ShiftTime: FC<{ label: string; shiftTime: string }> = (prop) => {
  return (
    <div className="bg-white py-0.5 px-1.5 rounded-[4px]">
      <div className="font-medium text-black text-[9px]">
        {prop.label} : {prop.shiftTime}
      </div>
    </div>
  );
};
