import React, { useState } from "react";
import Input from "../Input/index";
import { HiSearch } from "react-icons/hi";
import Button from "../Button/index";
import api from "../../store/api";
import { useFormik } from "formik";
import { useSeniority } from "../../store/seniority.state";
import { toast } from "react-toastify";
import { useModal } from "../../hooks/useModal";
import { useOutsideClick } from "rooks";
import DoctorShiftCard from "../Cards/DoctorShiftCard";
import Badge from "../Icons/Badge";
import Typography from "../Typography";
import { z } from "zod";
import { getSeniority } from "../../utils/fetchSeniorityColor";
import defaultAvater from "../../assets/images/avatar-default-light.webp";

interface CreateGroupForm {
  title: string;
  doctors: string[];
  seniority: number;
}

interface CreateGroupModalProps {
  onSubmit: () => void;
  unassignedDoctors: any[];
}

const CreateGroupModal = (props: CreateGroupModalProps) => {
  const { onSubmit, unassignedDoctors } = props;

  const { fetchFn: createGroup, isLoading } = api.group.useCreateGroup();

  const [selectedDoctors, setSelectedDoctors] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const toggleDoctor = (doctor: any) => {
    if (selectedDoctors.find((doc) => doctor._id === doc._id)) {
      setSelectedDoctors(
        selectedDoctors.filter((doc) => doc._id !== doctor._id)
      );
      formik.setFieldValue(
        "doctors",
        selectedDoctors
          .filter((doc) => doc._id !== doctor._id)
          .map((doc) => doc._id)
      );
    } else {
      setSelectedDoctors([...selectedDoctors, doctor]);
      formik.setFieldValue(
        "doctors",
        [...selectedDoctors, doctor].map((doc) => doc._id)
      );
    }
  };

  const { closeModal } = useModal();
  const [expand, setExpand] = useState(false);

  const searchWrapper = React.useRef<HTMLDivElement>(null);

  useOutsideClick(searchWrapper, () => {
    setExpand(false);
  });

  const { activeId: activeSeniorityId } = useSeniority();
  const formik = useFormik<CreateGroupForm>({
    initialValues: {
      title: "",
      doctors: [],
      seniority: activeSeniorityId,
    },
    validate: (values) => {
      const schema = z.object({
        title: z.string().nonempty("Title is required"),
        doctors: z
          .array(z.string())
          .min(1)
          .nonempty("Atleast one doctor is required"),
      });

      const result = schema.safeParse(values);
      if (!result.success) {
        return Object.fromEntries(
          result.error.errors.map((err) => [err.path[0], err.message])
        );
      }
      return {};
    },
    onSubmit: async (values) => {
      const groupCreate = await createGroup({
        data: values,
      });

      const { isSuccess, isError, error } = groupCreate.unwrap();

      if (isSuccess) {
        toast.success("Group created successfully");
        onSubmit?.();
      }

      if (isError) {
        toast.error(error?.response?.data?.message);
      }
      closeModal();
    },
  });

  return (
    <div className="bg-white  z-50 flex flex-col gap-6  outer-shadow w-screen outer-shadow max-w-screen-xl px-8 py-6 h-[680px]  mx-auto rounded-xl">
      <div className="text-xl text-black1 font-bold uppercase text-center">
        Create Group
      </div>
      <div className="grid grid-cols-3 gap-16">
        <div className="col-span-1 space-y-3">
          <div className="font-bold text-sm text-black2">Group Name</div>
          <Input
            type="text"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.title ? formik.errors.title : ""}
            placeholder="Enter Group Name (Ex: Freelancer Group)"
            className="!text-sm  placeholder:text-sm !rounded-2xl h-11 !border-2  !focus:ring-0 !placeholder:text-sm placeholder:text-black3"
          />
        </div>
        <div className="col-span-1 space-y-3">
          <div className="font-bold text-sm text-black2">
            Choose Users For Group
          </div>
          <div ref={searchWrapper} className="relative">
            <Input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setExpand(true);
              }}
              onFocus={() => setExpand(true)}
              icon={{
                content: (
                  <div className="h-11 w-11 bg-secondary rounded-full flex justify-center items-center -mt-3 -mr-3">
                    {" "}
                    <HiSearch size={20} className="text-white" />
                  </div>
                ),
                position: "right",
              }}
              placeholder="Search Doctor..."
              className="!text-sm placeholder:text-sm !rounded-full !border-2 !placeholder:text-sm"
            />
            {expand ? (
              <div className="absolute top-full z-50 p-2 rounded drop-shadow-md left-0 w-full bg-white max-h-80 overflow-y-auto">
                {/* {false ? (
                  <div className="flex flex-col justify-center items-center">
                    <MutatingDots
                      height={130}
                      width="100"
                      color="#67823A"
                      secondaryColor="#67823A"
                      radius="12.5"
                      ariaLabel="mutating-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : null} */}
                {!false &&
                  (unassignedDoctors
                    ?.filter((doctor) =>
                      doctor.user.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    .map((doctor: any) => (
                      <DoctorShiftCard
                        key={doctor._id}
                        onClick={() => {
                          toggleDoctor(doctor);
                        }}
                        variant="time"
                        selected={
                          !!selectedDoctors.find(
                            (doc) => doc._id === doctor._id
                          )
                        }
                        tags={doctor?.tags}
                        name={doctor.user.name}
                        icon={
                          <Badge
                            height={24}
                            width={24}
                            className="absolute top-0 right-0"
                            varient={getSeniority(activeSeniorityId)}
                          />
                        }
                        image={defaultAvater}
                      />
                    )) ??
                    [])}
                {!false && !unassignedDoctors?.length ? (
                  <div className="flex flex-col justify-center items-center min-h-72">
                    <Typography tag="p" className="font-semibold text-black3">
                      No doctors found.
                    </Typography>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-span-1 space-y-3"></div>
      </div>
      <div className="grid grid-cols-4 gap-6 w-full max-h-[70%] overflow-auto pl-2 pr-6 py-1">
        {unassignedDoctors?.map((doctor: any) => (
          <div className="col-span-1">
            <DoctorShiftCard
              key={doctor._id}
              onClick={() => {
                toggleDoctor(doctor);
              }}
              variant="experience"
              experienceLevel={doctor.experience}
              efficiencyScore={doctor.efficiencyScore}
              selected={!!selectedDoctors.find((doc) => doc._id === doctor._id)}
              name={doctor.user.name}
              tags={doctor?.tags}
              icon={
                <Badge
                  height={24}
                  width={24}
                  className="absolute top-0 right-0"
                  varient={getSeniority(activeSeniorityId)}
                />
              }
              image={defaultAvater}
            />
          </div>
        ))}
        {formik.touched.title && formik.errors.doctors ? (
          <Typography tag="small" className="font-semibold text-red-500">
            {formik.errors.doctors}
          </Typography>
        ) : null}
      </div>

      <div className="flex items-center justify-center mt-auto gap-8 max-w-md mx-auto w-full">
        <Button
          onClick={closeModal}
          disabled={isLoading}
          className="flex justify-center px-6 !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl !h-11"
        >
          Cancel
        </Button>
        <Button
          onClick={(e: any) => formik.handleSubmit(e)}
          className="flex justify-center whitespace-nowrap px-6 text-center !rounded-xl !h-11"
          disabled={isLoading}
        >
          {isLoading ? "Creating..." : "Create Group"}
        </Button>
      </div>
    </div>
  );
};

export default CreateGroupModal;
