import { useState } from "react";
import deepEqual from "deep-equal";

// To be used with non external communication or DOM manipulation callbacks
const useRunOnChange = (value: any, initialValue: any, callback: () => any) => {
  const [prevValue, setPrevValue] = useState(initialValue);
  if (!deepEqual(value, prevValue, { strict: true })) {
    callback();
    setPrevValue(value);
  }
};

export default useRunOnChange;
