import React, { FC } from 'react'
interface clockI {
    className?: string;
}
const Clock: FC<clockI> = ({ className }) => {
    return (
        <svg className={className} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 14.5C11.0376 14.5 13.5 12.0376 13.5 9C13.5 5.96243 11.0376 3.5 8 3.5C4.96243 3.5 2.5 5.96243 2.5 9C2.5 12.0376 4.96243 14.5 8 14.5Z" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 8.99978L10.4749 6.5249" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.5 1.5H9.5" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default Clock