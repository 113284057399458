import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Button from "../Button";
import Input from "../Input";
import { useFormik } from "formik";
import { z } from "zod";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import api from "../../store/api";

interface ForgotPasswordForm {
  email: string;
}

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { fetchFn: resetPassword } = api.auth.useResetPassword();

  const navigate = useNavigate();

  const formik = useFormik<ForgotPasswordForm>({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const schema = z.object({
        email: z
          .string({ required_error: "Email is required!" })
          .email("Invalid email!")
          .nonempty("Email is required!"),
      });
      const result = schema.safeParse(values);

      if (!result.success) {
        const errors = Object.entries(
          result.error.flatten().fieldErrors
        ).reduce((acc, [key, value]) => {
          acc[key] = value[0];
          return acc;
        }, {} as Record<string, string>);
        console.log(errors);
        return errors;
      }
      return {};
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      const reset = await resetPassword({
        data: {
          email: values.email,
        },
      });

      const { isSuccess, data, isError, error } = reset.unwrap();

      if (isSuccess) {
        toast.success(
          data?.message ?? "Password reset link sent to your email"
        );
        setIsLoading(false);
        navigate("/otp", { state: { email: values.email } });
      }

      if (isError) {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
        setIsLoading(false);
      }
    },
  });

  return (
    <div className="!max-w-xl py-8 px-12 w-full rounded-xl bg-white h-fit outer-shadow">
      <div className="mb-8 dm font-bold text-4xl text-black">
        Forgot Password!
      </div>
      {/* <div className="mb-16 flex justify-center"></div> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-6">
          <Input
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.email ? formik.errors.email : ""}
            placeholder="Enter Email"
          />
        </div>

        <div className="mt-8 mb-4">
          <Button
            type="submit"
            disabled={isLoading}
            className="flex justify-center items-center"
            name="Submit"
          >
            {isLoading ? (
              <ThreeDots
                height={80}
                width="80"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
