import { useState } from "react";
import HorizontalScrollContainer from "../../HorizontalScrollContainer";
import TagPill from "../../TagPill";
import Envelop from "../../Icons/Envelop";
import Phone from "../../Icons/Phone";

const DoctorInfoWindowDetails = ({
  email,
  phone,
  experience,
  effeciency,
  groups,
  subGroups,
  tags,
  type = "responsive",
}: {
  email: string;
  phone: string;
  experience: number;
  effeciency: number;
  groups: any[];
  subGroups: any[];
  tags: any[];
  type?: "responsive" | "user-management";
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  function onTabClick(index: number): void {
    setActiveIndex(index);
  }
  return (
    <div
      className={`h-full w-full flex flex-col`}
    >
      <HorizontalScrollContainer>
        {[...groups, ...subGroups]?.map((obj: { title: string }, i: number) => (
          <TagPill
            key={i}
            name={obj.title}
            className={"w-fit"}
            activeBgColor={"bg-secondary"}
            isActive={true}
            onClick={() => {
              console.log("TAG PILL");
            }}
          />
        ))}
        {tags?.map((obj: { value: string }, i: number) => (
          <TagPill
            key={i}
            name={obj.value}
            className={"w-fit"}
            activeBgColor={"bg-secondary"}
            isActive={true}
            onClick={() => {
              console.log("TAG PILL");
            }}
          />
        ))}
      </HorizontalScrollContainer>
      <div
        className={`grid grid-cols-2 mt-2 gap-2 grid-rows-3 flex-grow ${
          type === "user-management" ? "bg-white" : "bg-gray"
        }`}
      >
        <div
          className={`col-span-1 rounded-xl ${
            type === "user-management" ? "bg-gray row-span-2" : "bg-white row-span-1"
          }`}
        >
          <div className="p-3 flex flex-col justify-around h-full">
            <div className="flex justify-start items-center gap-2">
              <div className="">
                <Envelop />
              </div>
              <div className="font-medium text-black1 text-[15px]">{email}</div>
            </div>
            <div className="flex justify-start items-center gap-2">
              <div className="">
                <Phone />
              </div>
              <div className="font-medium text-black1 text-[15px]">{phone}</div>
            </div>
          </div>
        </div>
        <div
          className={`col-span-1 row-span-3 rounded-xl ${
            type === "user-management" ? "bg-gray" : "bg-white"
          }`}
        >
          <div className="flex flex-col gap-y-4 justify-center items-center font-medium text-[10px] h-full">
            <div className="flex justify-center items-center gap-2 leading-5">
              {activeIndex == 0 ? (
                <>
                  <div className="font-medium text-[54px] text-[#1B4B77]">
                    {experience}
                  </div>
                  <div className="font-medium text-base text-[#1B4B77]">
                    yrs
                  </div>
                </>
              ) : (
                <div className="font-medium text-[54px] text-[#1B4B77]">
                  {effeciency} / 10
                </div>
              )}
            </div>
            <div className="font-medium text-center text-[18px] text-[#1B4B77]">
              {" "}
              {activeIndex == 0 ? "Experience" : "Efficiency"}{" "}
            </div>
            <div className="flex gap-2 items-center w-2/3">
              {Array.from({ length: 2 }).map((_, value) => (
                <button
                  onClick={() => onTabClick(value)}
                  className={`h-1 rounded ${
                    value == activeIndex ? "bg-[#1B4B77]" : "bg-[#E0E0E0]"
                  } `}
                  style={{ width: `${100 / 2}%` }}
                ></button>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`col-span-1 rounded-xl ${
            type === "user-management" ? "bg-gray row-span-1" : "bg-white row-span-2"
          }`}
        >
          <div className="flex justify-center items-center font-medium text-[15px] h-full text-black">
            User Preferences
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorInfoWindowDetails;
