import { MutableRefObject, useEffect } from "react";

const useRightClick = (
  ref: MutableRefObject<HTMLElement | null>,
  rightClickHandler: () => void,
) => {
  useEffect(() => {
    const handleContextMenu = (e: Event) => {
      e.preventDefault();
      rightClickHandler();
    };

    ref.current?.addEventListener("contextmenu", handleContextMenu);
    const cleanUpCopy = ref.current;

    return () => {
      cleanUpCopy?.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
};

export default useRightClick;
