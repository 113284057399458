import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { toast } from "react-toastify";

const useHandleSuccessErrors = ({
  isSuccess,
  isError,
  error,
  successMessage,
  successFunction,
}: {
  isSuccess: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  successMessage: string;
  successFunction?: () => Promise<void>;
}) => {
  useEffect(() => {
    if (isSuccess) {
      toast.success(successMessage, {
        toastId: successMessage.toLowerCase().replace(/\s/g, "-"),
      });
      successFunction ? successFunction().then(() => undefined) : undefined;
    }
    if (isError && error) {
      toast.error(
        ((error as FetchBaseQueryError).data as { message: string }).message
      );
    }
  }, [isError, isSuccess, error]);
};

export default useHandleSuccessErrors;
