import DoctorShiftCard from "../Cards/DoctorShiftCard";
import Input from "../Input/index";
import { HiSearch } from "react-icons/hi";
import Badge from "../Icons/Badge";
import Button from "../Button/index";
import GroupPill from "../Pills/GroupPill";
import { useEffect, useState } from "react";
import { useCreateLocation, useLocation } from "../../store/location.store";
import api from "../../store/api";
import { useDebouncedCallback } from "use-debounce";
import { useSeniority } from "../../store/seniority.state";
import Typography from "../Typography";
import { MutatingDots } from "react-loader-spinner";
import { useModal } from "../../hooks/useModal";
import { toast } from "react-toastify";
import defaultAvatar from "../../assets/images/avatar-default-light.webp";
import { useGetLocationsQuery } from "../../store/locationApi";

interface LocationSettingsModalProps {
  location: any;
}

const LocationSettingsModal = (props: LocationSettingsModalProps) => {
  const { location } = props;

  const {
    seniorities,
    setSeniorities,
    groups,
    setGroups,
    locationName,
    setLocationName,
  } = useCreateLocation();

  const { setDisabled, seniorities: allSeniorities } = useSeniority();
  const { setActiveId: setActiveLocationId, setLocations } = useLocation();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setSeniorities(location?.seniorities);
    setGroups(location?.defaultGroups);
    setLocationName(location?.label);
  }, []);

  const toggleSeniority = (id: number) => {
    if (seniorities.includes(id)) {
      setSeniorities(seniorities.filter((seniority) => seniority !== id));
    } else {
      setSeniorities([...seniorities, id]);
    }
  };

  const { closeModal } = useModal();

  const {
    fetchFn: getGroups,
    data: groupsData,
    isLoading: isGroupLoading,
  } = api.group.useGetGroups();
  const debouncedGetGroups = useDebouncedCallback(
    (args) => getGroups(args),
    500,
  );

  const { refetch: refetchLocations, isLoading: isLocationLoading } =
    useGetLocationsQuery({
      seniority: 4,
      all: true,
    });

  useEffect(() => {
    debouncedGetGroups({
      params: {
        q: searchQuery,
        seniority: 4,
      },
    });
  }, [searchQuery]);

  const { fetchFn: updateLocation, isLoading: isLocationAddLoading } =
    api.location.useUpdateLocation();

  const editLocation = async () => {
    const payload = {
      locationId: location._id,
      label: locationName,
      seniorities,
      groups: groups.map((group) => group._id),
    };

    const res = await updateLocation({ data: payload });
    const { isSuccess, isError, error, data: updatedLocation } = res.unwrap();

    if (isSuccess) {
      toast.success("Location updated successfully");
      await refetchLocations();

      // const { data: locations } = locationsResp.unwrap();

      // const moddedLocations = locations?.locations?.map((location) => {
      //   if (location._id === payload.locationId) {
      //     return {
      //       ...location,
      //       label: updatedLocation.label,
      //       seniorities: updatedLocation.seniorities,
      //       defaultGroups: payload.groups,
      //     };
      //   }
      //   return location;
      // });

      // // disable seniorities
      // if (moddedLocations?.length) {
      //   const location = moddedLocations.find(
      //     (loc) => loc._id === payload.locationId,
      //   );

      //   for (const s of allSeniorities) {
      //     if (location?.seniorities.includes(s.id)) {
      //       setDisabled(s.id, false);
      //     } else {
      //       setDisabled(s.id, true);
      //     }
      //   }
      // }

      // setLocations(moddedLocations);
      // if (locations?.locations?.length === 1) {
      //   setActiveLocationId(locations.locations[0]._id);
      // }
    }

    if (isError) {
      toast.error(error?.response?.data?.message);
    }

    closeModal();
  };

  const [touched, setTouched] = useState<boolean>(false);

  return (
    <div className="flex bg-white flex-col w-full gap-6 outer-shadow max-w-lg px-8 py-6 mx-auto rounded-xl">
      <div className="font-semibold flex flex-col gap-y-2 text-xl text-black2">
        <small>Location Name</small>
        <Input
          type="text"
          value={locationName}
          onChange={(e) => setLocationName(e.target.value)}
          placeholder="Location Name"
          onFocus={() => setTouched(true)}
          errorMsg={
            touched
              ? !locationName.length
                ? "Location name is required"
                : ""
              : ""
          }
          className="!text-sm placeholder:text-sm !rounded-2xl !placeholder:text-sm"
        />
      </div>
      <div className="font-semibold text-xl text-black2">
        Enable This Location For
      </div>
      <div className="grid grid-cols-3 gap-x-4">
        {allSeniorities.map((seniority, i) => {
          return (
            <div key={i} className="flex items-center col-span-1">
              <input
                id={`checkbox-${seniority.id}`}
                type="checkbox"
                checked={seniorities.includes(seniority.id)}
                onChange={(e) => toggleSeniority(seniority.id)}
                className="w-5 h-5 !rounded-lg text-blue-600 bg-gray-100 border-gray-300  "
              />
              <label
                htmlFor={`checkbox-${seniority.id}`}
                className="ml-3 text-base font-semibold text-black1 "
              >
                <seniority.label isActive={true} />
              </label>
            </div>
          );
        })}
      </div>

      <div className="font-semibold text-xl text-black2">
        Assign User Groups to Location
      </div>
      <div className="flex flex-wrap items-center gap-2">
        {groups.map((group) => (
          <GroupPill
            name={group.title}
            className="!py-2 px-4 bg-secondary!"
            onDelete={() => {
              setGroups(groups.filter((g) => g._id !== group._id));
            }}
          />
        ))}
      </div>
      <Input
        type="text"
        icon={{
          content: <HiSearch size={20} />,
          position: "left",
        }}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search Group or People..."
        className="!text-sm placeholder:text-sm !rounded-2xl !placeholder:text-sm"
      />
      <div className="flex flex-col gap-y-2 max-h-72 overflow-y-auto">
        {isGroupLoading ? (
          <div className="flex flex-col justify-center items-center">
            <MutatingDots
              height={130}
              width="100"
              color="#67823A"
              secondaryColor="#67823A"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : null}
        {!isGroupLoading &&
          groupsData?.map((group: any) => (
            <DoctorShiftCard
              onClick={() => {
                if (groups.includes(group)) {
                  setGroups(groups.filter((g) => g._id !== group._id));
                } else {
                  setGroups([...groups, group]);
                }
              }}
              selected={groups.includes(group)}
              key={group._id}
              variant="time"
              name={group.title}
              icon={
                <Badge
                  height={24}
                  width={24}
                  className="absolute top-0 right-0"
                  varient={"blue"}
                />
              }
              image={defaultAvatar}
            />
          ))}
        {!isGroupLoading && !groupsData?.length ? (
          <div className="flex flex-col justify-center items-center min-h-72">
            <Typography tag="p" className="font-semibold text-black3">
              No groups found.
            </Typography>
          </div>
        ) : null}
      </div>

      <div className="flex items-center justify-center gap-8">
        <Button
          disabled={isLocationAddLoading || isLocationLoading}
          className="flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl h-10"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isLocationAddLoading || isLocationLoading}
          className="flex justify-center text-center !rounded-xl"
          onClick={editLocation}
        >
          {isLocationAddLoading || isLocationAddLoading
            ? "Adding..."
            : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default LocationSettingsModal;
