import { FC } from "react";
import Plus from "../Icons/Plus";
import { useCreateScheduleMutation } from "../../store/rosterApi";
import useHandleSuccessErrors from "../../hooks/handleSuccessErrors";
import { useEstimationContext } from "../../hooks/useEstimationContext";
import Trash from "../Icons/Trash";

interface DoctorCardProps {
  name: string;
  doctor: any;
  doctorId: string;
  isActive: boolean;
  canAssign?: boolean;
  isFetching: boolean;
  refetchShifts?: () => Promise<void>;
  refetchDoctors?: () => Promise<void>;
  onClickHandler: () => void;
  onDoubleClickHandler?: () => void;
  deleteHandler?: () => void;
  status?: "available" | "warning" | "unavailable";
  seniority: "3" | "2" | "1";
  className?: string;
  type: "doctor-filter" | "doctor-filter-no-shift" | "user-management" | "group-management";
}
export const borderColors = {
  "3": "border-[#DF9C28]",
  "2": "border-[#84ACE2]",
  "1": "border-[#E57041]",
};

const bgColors = {
  "3": "bg-[#DF9C28] ",
  "2": "bg-[#84ACE2] ",
  "1": "bg-[#E57041] ",
};

const bgHover = {
  "3": "hover:bg-[#DF9C28] hover:bg-opacity-50 ",
  "2": "hover:bg-[#84ACE2] hover:bg-opacity-50 ",
  "1": "hover:bg-[#E57041] hover:bg-opacity-50 ",
};

const textColors = {
  "3": "text-[#DF9C28]",
  "2": "text-[#84ACE2]",
  "1": "text-[#E57041]",
};

const activeColor = "text-white";

const DoctorCard: FC<DoctorCardProps> = (props) => {
  const {
    name,
    onClickHandler,
    onDoubleClickHandler: onLongClickHandler,
    isActive,
    seniority,
    status,
    refetchShifts,
    refetchDoctors,
    deleteHandler,
    doctorId,
    canAssign,
    isFetching,
    className,
    type,
  } = props;

  // Move this out of this component
  const [createSchedule, { isLoading, isSuccess, isError, error }] =
    useCreateScheduleMutation();

  const { editShiftId } = useEstimationContext();

  useHandleSuccessErrors({
    isSuccess,
    isError,
    error,
    successMessage: "Schedule added to shift!",
    // This can be pushed to the Doctor Filter Window Component
    successFunction: async () => {
      await Promise.all([refetchShifts!(), refetchDoctors!()]);
    },
  });

  const statusColor = {
    available: "bg-secondary",
    unavailable: "bg-[#D6764C] ",
    warning: "bg-[#F4C94E] ",
  };

  return (
    <div
      className={`flex h-14 shadow-1 rounded-xl group bg-white ${
        isLoading || isFetching ? "animate-pulseFast" : ""
      } ${className ?? ""}`}
    >
      <div
        className={`${
          type === "doctor-filter" || type === "group-management"
            ? "rounded-l-xl"
            : "rounded-xl"
        } flex-grow border-l-[3px] transition-all duration-500 cursor-pointer px-2 ${
          isActive ? bgColors[seniority] : bgHover[seniority]
        } ${borderColors[seniority]}`}
        onClick={onClickHandler}
        onDoubleClick={onLongClickHandler}
      >
        <div
          className={`font-bold text-base text-black1 h-full flex items-center ${
            isActive ? activeColor : ""
          }`}
        >
          {name ?? "<DOCTOR / USER NAME>"}
        </div>
      </div>
      {type === "doctor-filter" && status && (
        <div className="w-8 grid grid-cols-1 divide-y divide-gray6 h-full shadow-1 rounded-r-xl">
          <div className="col-span-1 flex justify-center items-center ">
            <div
              className={`${statusColor[status]} bg-opacity-50 rounded-full p-0.5 w-fit`}
            >
              <div
                className={`w-2 h-2 rounded-full ${statusColor[status]} `}
              ></div>
            </div>
          </div>
          <div
            className={`col-span-1 flex justify-center items-center ${
              !canAssign || isLoading || isFetching
                ? "bg-gray2 opacity-100 z-10"
                : ""
            }`}
          >
            <button
              disabled={!canAssign || isLoading || isFetching}
              onClick={async () => {
                await createSchedule({ shiftId: editShiftId, doctorId });
              }}
            >
              <Plus
                className="w-4 h-4 cursor-pointer"
                color={!canAssign ? "gray" : "black"}
              />
            </button>
          </div>
        </div>
      )}
      {type === "group-management" && deleteHandler && (
        <div
          className={`w-8 divide-y divide-gray6 h-full shadow-1 rounded-r-xl flex justify-center items-center ${
            isLoading || isFetching ? "bg-gray2 opacity-100 z-10" : ""
          }`}
        >
          <button
            disabled={isLoading || isFetching}
            onClick={async () => {
              deleteHandler?.();
            }}
          >
            <Trash />
          </button>
        </div>
      )}
    </div>
  );
};

export default DoctorCard;
