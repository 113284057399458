import Button from "./Button";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import Typography from "./Typography";
import { DateTime } from "luxon";
import { TIME_ZONE } from "../constants";

interface CalendarSwitcherProps {
  month: number;
  year: number;
  disabledLeft?: boolean;
  disabledRight?: boolean;
  className?: string;
  buttonClass?: string;
  iconClass?: string;
  onPrev?: () => void;
  onNext?: () => void;
}
const CalendarSwitcher = (props: CalendarSwitcherProps) => {
  const {
    disabledLeft = false,
    disabledRight = false,
    month,
    className,
    year,
    buttonClass,
    iconClass,
    onPrev,
    onNext,
  } = props;
  return (
    <div className={`flex items-center gap-x-4 p-2 ${className}`}>
      <Button
        disabled={disabledLeft}
        className={`p-2 h-8 w-8 ${buttonClass}`}
        onClick={onPrev}
      >
        <HiChevronLeft className={iconClass} />
      </Button>
      <Typography
        tag="span"
        className="font-semibold whitespace-nowrap text-sm"
      >
        {DateTime.fromObject({ year, month }, { zone: TIME_ZONE }).toFormat(
          "MMM yyyy",
        )}
      </Typography>
      <Button
        disabled={disabledRight}
        className={`p-2 h-8 w-8 ${buttonClass}`}
        onClick={onNext}
      >
        <HiChevronRight className={iconClass} />
      </Button>
    </div>
  );
};

export default CalendarSwitcher;
