export const TIME_ZONE = "Asia/Kolkata";
export const DISPLAY_DATE_FORMAT = "dd MMM yyyy";

export const DISPLAY_DATE_FORMAT_LONG = "dd MMMM yyyy";
export const API_DATE_FORMAT = "dd/MM/yyyy";
export const API_TIME_FORMAT = "h:mm a";

export const DATE_RANGE = 1;

export const CACHE_LIFE = 4 * 3600;
