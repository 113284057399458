import { useEffect, useState } from "react";
import { useSeniority } from "../../store/seniority.state";
import Button from "../Button/index";
import CalenderCard from "../Calender/CalenderCard";
import DoctorCardWithSeniority from "../Cards/DoctorCardWithSeniority";
import ShiftCard from "../Cards/ShiftCard";
import NavTab from "../NavTabs";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { toast } from "react-toastify";
import { TIME_ZONE } from "../../constants";
import { useGetShiftsSingle } from "../../hooks/useGetShifts";
import { DateTime } from "luxon";

interface EstimatedDoctorSummaryProps {
  initialDate: Date;
}

const EstimatedDoctorSummary = (props: EstimatedDoctorSummaryProps) => {
  const [date, setDate] = useState<Date>(props.initialDate);
  const { seniorities } = useSeniority();
  const [activeSeniorityId, setActiveId] = useState<number>(1);

  const {
    data: shiftData,
    isLoading: isShiftsLoading,
    isError,
    error,
  } = useGetShiftsSingle({
    activeDate: date,
  });

  useEffect(() => {
    if (isError) {
      toast.error((error as any).data.message);
    }
  }, [isError, error]);

  const [activeShiftTimingIndex, setActiveShiftTimingIndex] =
    useState<number>(0);

  const onPrev = () => {
    setDate(
      DateTime.fromJSDate(date)
        .setZone(TIME_ZONE)
        .plus({ days: -1 })
        .toJSDate(),
    );
  };

  const onNext = () => {
    setDate(
      DateTime.fromJSDate(date).setZone(TIME_ZONE).plus({ days: 1 }).toJSDate(),
    );
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div className="bg-white h-min rounded-full">
        <NavTab
          tabs={[...seniorities]}
          activeId={activeSeniorityId}
          tabType="neumorphism"
          onChange={(id) => {
            setActiveId(id);
          }}
        />
      </div>
      <div className="outer-shadow bg-white w-full max-w-[50rem] px-8 py-6 mx-auto rounded-xl">
        <div className="font-bold text-black2 text-xl mb-6">
          Estimated Doctors Summary
        </div>
        <div className="border-b-4 border-primary pb-10">
          <div className="grid grid-cols-3 gap-8 w-full">
            <div className="col-span-1">
              <CalenderCard
                showContextMenu={false}
                day={DateTime.fromJSDate(date)
                  .setZone(TIME_ZONE)
                  .toFormat("EEEE")}
                date={date}
                monthName={DateTime.fromJSDate(date)
                  .setZone(TIME_ZONE)
                  .toFormat("dd MMMM")}
                className="!h-36 pt-4 !pb-4"
                others={
                  <div className="flex items-end justify-between mt-auto">
                    <Button
                      disabled={false}
                      className=" !h-8 !w-8 bg-green1 outer-shadow"
                      onClick={onPrev}
                    >
                      <HiChevronLeft className="text-white w-20 h-20 mx-auto" />
                    </Button>

                    <Button
                      disabled={false}
                      className=" !h-8 !w-8 bg-green3 outer-shadow "
                      onClick={onNext}
                    >
                      <HiChevronRight className="text-white w-20 h-20 ml-0.5" />
                    </Button>
                  </div>
                }
              />
            </div>
            <div className="col-span-2">
              <ShiftCard
                skeleton={isShiftsLoading}
                time={{
                  from: shiftData?.length
                    ? DateTime.fromISO(
                        shiftData[activeShiftTimingIndex].time.from,
                      )
                        .setZone(TIME_ZONE)
                        .toJSDate()
                    : new Date(),
                  to: shiftData?.length
                    ? DateTime.fromISO(
                        shiftData[activeShiftTimingIndex].time.to,
                      )
                        .setZone(TIME_ZONE)
                        .toJSDate()
                    : new Date(),
                }}
                showNext={
                  activeShiftTimingIndex < (shiftData?.[0]?.length ?? 0) - 1
                }
                showPrev={activeShiftTimingIndex > 0}
                onNext={() => {
                  setActiveShiftTimingIndex(activeShiftTimingIndex + 1);
                }}
                onPrev={() => {
                  setActiveShiftTimingIndex(activeShiftTimingIndex - 1);
                }}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-8 mt-8">
          {isShiftsLoading
            ? [1, 2].map((_, index) => (
                <div className="col-span-1" key={index}>
                  <DoctorCardWithSeniority skeleton={true} name={"loading"} />
                </div>
              ))
            : null}
          {!isShiftsLoading &&
          !isError &&
          shiftData?.[0]?.length &&
          shiftData?.[0][activeShiftTimingIndex]
            ? shiftData?.[0][activeShiftTimingIndex].slots.map((slot: any) => (
                <div className="col-span-1" key={slot._id}>
                  <DoctorCardWithSeniority
                    name={slot.schedule?.doctor.user.name ?? "Empty Slot"}
                  />
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default EstimatedDoctorSummary;
