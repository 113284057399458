import { useEffect, useState } from "react";
import Switch from "rc-switch";
import { InfinitySpin } from "react-loader-spinner";
import { motion } from "framer-motion";
import { DateTime } from "luxon";
import "../../assets/rc-switch.css";

import OuterWindow from "./OuterWindow";
import ShiftCard from "./ShiftCard";
import RequestPhaseWindow from "./RequestPhaseWindow";

import { sortShifts, useGetShiftsSingle } from "../../hooks/useGetShifts";
import {
  useGetDoctorStatsQuery,
  useGetDoctorsFilterQuery,
  useGetDoctorsSuggestionQuery,
} from "../../store/rosterApi";
import {
  useGetDayOffRequestsQuery,
  useGetShiftRequestsQuery,
} from "../../store/requestsApi";
import { useEstimationContext } from "../../hooks/useEstimationContext";
import { useLocation } from "../../store/location.store";
import { formatDateAPI, formatDisplayDate } from "../../utils/formatTime";
import { usePrefetchContext } from "../../hooks/usePrefetchContext";
import { Shift } from "../../interface/shift";
import {
  useGetCalendarQuery,
  useGetDayOffsQuery,
} from "../../store/locationApi";
import { useSeniority } from "../../store/seniority.state";
import { sortRequests } from "../../utils/sortRequests";
import { TIME_ZONE } from "../../constants";
import useReloadFirebase from "../../hooks/useReloadFirebase";
import { checkContested, shiftRequestFilter } from "../../utils/shiftRequests";

type PrimaryMonitorProps = {
  activeDate: Date;
  className?: string;
  bodyClass?: string;
};

const getDisplayType = (
  shiftData: any,
  isShiftsLoading: boolean,
  isShiftsFetching: boolean,
  isOpenRequest: boolean,
): "shifts" | "no-shifts" | "requests-phase" | "loading" => {
  if (isShiftsLoading || (!shiftData && isShiftsFetching)) {
    return "loading";
  }
  if (isOpenRequest) {
    return "requests-phase";
  }
  if (shiftData && shiftData.length > 0) {
    return "shifts";
  }
  return "no-shifts";
};

const PrimaryMonitor = ({
  activeDate,
  className,
  bodyClass,
}: PrimaryMonitorProps) => {
  const [isOpenRequest, setIsOpenRequest] = useState<boolean>(false);
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();

  const { editShiftId, doctorFilterSeniority, setRequestTab } =
    useEstimationContext();

  const handleIsRequest = () => {
    setIsOpenRequest(!isOpenRequest);
  };

  const {
    data: shiftData,
    isLoading: isShiftsLoading,
    isFetching: isShiftsFetching,
    refetch: refetchShifts,
  } = useGetShiftsSingle({ activeDate });

  const {
    data: dayOffData,
    isLoading: isDayOffLoading,
    isFetching: isDayOffFetching,
    refetch: refetchDayOffs,
  } = useGetDayOffsQuery({ date: formatDateAPI(activeDate) });

  const { refetch: refetchSuggestedDoctors } = useGetDoctorsSuggestionQuery(
    { shiftId: editShiftId },
    { skip: !editShiftId },
  );

  const { refetch: refetchFilterDoctors } = useGetDoctorsFilterQuery(
    {
      shiftId: editShiftId,
      seniority: doctorFilterSeniority,
    },
    { skip: !editShiftId },
  );

  const { triggerDoctorPrefetch, triggerStatsPrefetch } = usePrefetchContext();

  const refetchDoctors = async () => {
    await Promise.all([refetchSuggestedDoctors(), refetchFilterDoctors()]);
    triggerDoctorPrefetch();
    triggerStatsPrefetch();
  };

  const {
    data: shiftRequests,
    isLoading: isShiftRequestsLoading,
    isFetching: isShiftRequestsFetching,
    refetch: refetchShiftRequests,
  } = useGetShiftRequestsQuery(
    {
      locationId: activeLocationId,
      date: formatDateAPI(activeDate),
      seniority: activeSeniorityId,
    },
    { skip: !activeLocationId },
  );
  // Can only be used once, send to context or add another hook
  const { reload, setFalse } = useReloadFirebase();

  useEffect(() => {
    const asyncFn = async () => {
      if (reload === true) {
        try {
          await refetchShiftRequests();
        } catch (error) {
          console.error(error);
        }
        try {
          await refetchDayOffRequests();
        } catch (error) {
          console.error(error);
        }
        setFalse();
      }
    };
    asyncFn().then(() => undefined);
  }, [reload]);

  const activeDateTime = DateTime.fromJSDate(activeDate).setZone(TIME_ZONE);

  const { refetch: refetchStats } = useGetDoctorStatsQuery({
    seniority: activeSeniorityId,
    month: activeDateTime.get("month"),
    year: activeDateTime.get("year"),
  });

  const {
    data: dayOffRequests,
    isLoading: isDayOffRequestsLoading,
    isFetching: isDayOffRequestsFetching,
    refetch: refetchDayOffRequests,
  } = useGetDayOffRequestsQuery({
    date: formatDateAPI(activeDate),
  });

  const { data: calendarData } = useGetCalendarQuery(
    {
      locationId: activeLocationId,
      month: activeDateTime.get("month"),
      year: activeDateTime.get("year"),
      seniority: activeSeniorityId,
    },
    { skip: !activeLocationId },
  );

  const bodyNodeType = getDisplayType(
    shiftData,
    isShiftsLoading,
    isShiftsFetching,
    isOpenRequest,
  );

  return (
    <OuterWindow
      className={`w-full h-full ${className}`}
      title={`${formatDisplayDate(activeDate)} ${
        isOpenRequest ? "(Requests)" : ""
      }`}
      titleClassName="text-white transition duration-500 "
      headerChildNode={
        <div
          className={`absolute top-0 bottom-0 right-6 h-fit my-auto ${
            isOpenRequest
              ? "custom-rc-switch-checked"
              : "custom-rc-switch-unchecked"
          }`}
        >
          <Switch onClick={() => handleIsRequest()} checked={isOpenRequest} />
        </div>
      }
      headerClassName={`${
        !isOpenRequest ? "bg-secondary" : "bg-teal3"
      } relative transition duration-500`}
      bodyClass={`grid ${
        !isOpenRequest ? "grid-cols-2" : "grid-cols-1"
      } transition duration-500 gap-6 ${bodyClass}`}
      bodyChildNode={{
        shifts: () =>
          [...shiftData].sort(sortShifts).map((shift: Shift) => {
            const currentShiftRequests =
              shiftRequests?.filter(shiftRequestFilter(shift._id)) ?? [];
            const isContested = checkContested(currentShiftRequests);
            return (
              <ShiftCard
                isFetching={isShiftsFetching}
                refetchShifts={async () => {
                  await refetchShifts();
                }}
                type={shift.type}
                refetchDoctors={refetchDoctors}
                key={shift._id}
                shiftId={shift._id}
                time={shift.time}
                slots={shift.slots}
                windowType={"primary"}
                requestsStatus={
                  shiftRequests?.find(shiftRequestFilter(shift._id, "all"))
                    ? isContested
                      ? "contested"
                      : "requests"
                    : "no-requests"
                }
                requestHandler={() => {
                  setIsOpenRequest(true);
                  setRequestTab(shift._id);
                }}
              />
            );
          }) ?? [],
        "no-shifts": () => (
          <div className="col-span-2">
            <motion.div
              key="noDataTransition"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 210 }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.8 }}
              className="font-medium flex justify-center items-center h-[210px]"
            >
              No Shifts Added
            </motion.div>
          </div>
        ),
        "requests-phase": () => (
          <RequestPhaseWindow
            shifts={[...shiftData].sort(sortShifts)}
            shiftRequests={
              shiftRequests ? [...shiftRequests].sort(sortRequests) : undefined
            }
            dayOffRequests={
              dayOffRequests
                ? [...dayOffRequests].sort(sortRequests)
                : undefined
            }
            dayOff={dayOffData}
            isLoading={
              isShiftRequestsLoading ||
              isDayOffRequestsLoading ||
              isDayOffLoading
            }
            isFetching={
              isShiftRequestsFetching ||
              isDayOffRequestsFetching ||
              isDayOffFetching
            }
            refetchShiftRequests={async () => {
              await refetchShiftRequests();
            }}
            refetchDayOffRequests={async () => {
              await refetchDayOffRequests();
            }}
            refetchShifts={async () => {
              await refetchShifts();
            }}
            refetchDayOffs={async () => {
              await refetchDayOffs();
            }}
            refetchStats={async () => {
              await refetchStats();
            }}
            refetchDoctors={async () => {
              await refetchDoctors();
            }}
            deadline={calendarData?.deadline}
          />
        ),
        loading: () => (
          <div className="m-auto col-span-2">
            <InfinitySpin width="200" color="#67823A" />
          </div>
        ),
      }[bodyNodeType]()}
    />
  );
};

export default PrimaryMonitor;
