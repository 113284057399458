import Search from "../Icons/Search";
import Close from "../Icons/Close";

const DoctorSearch = ({
  className,
  onFocus,
  onBlur,
  searchValue,
  setSearchValue,
}: {
  className: string;
  onFocus: () => void;
  onBlur: () => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
}) => {
  return (
    <>
      <div
        className={`relative w-fit ${className}`}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search"
          className="h-[46px] w-full border border-solid border-[#BDBDBD] rounded-xl outline-none appearance-none px-6 py-2 bg-gray6 font-medium text-sm text-black3 transition-all"
        />
        {searchValue.length == 0 && (
          <Search className="absolute top-0 bottom-0 my-auto right-5 z-50" />
        )}
        {searchValue.length > 0 && (
          <Close
            className="absolute top-0 bottom-0 my-auto right-5 cursor-pointer z-50"
            onClick={() => setSearchValue("")}
          />
        )}
      </div>
    </>
  );
};

export default DoctorSearch;
