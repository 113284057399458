import { create } from "zustand";

import { ApiState, getInitialState } from ".";
import { axiosApi } from "./axios";

type GetGroups = ApiState<any, any, any>;

const useGetGroups = create<GetGroups>((set) => ({
  ...getInitialState<any, any, any>(
    {
      method: "GET",
      url: "/api/group",
    },
    set
  ),
}));

type GetSubGroupsByGroups = ApiState<any, any, any>;

const useGetSubGroupsByGroups = create<GetSubGroupsByGroups>((set) => ({
  ...getInitialState<{ subGroupId: string; doctors: string[] }, any, any>(
    {},
    set
  ),
  fetchFn: async (body) => {
    let data: any = null as any;
    let error: any = null;
    let isError = false;
    let isLoading = false;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      });
      isLoading = true;
      const res = await axiosApi({
        url: `/api/subgroup/group/${body?.params?.groupId}?q=${body?.params?.q}`,
        method: "GET",
      });
      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
}));

type CreateGroup = ApiState<
  {
    title: string;
    doctors: string[];
    seniority: number;
  },
  any,
  any
>;

const useCreateGroup = create<CreateGroup>((set) => ({
  ...getInitialState<any, any, any>(
    {
      method: "POST",
      url: "/api/group/create",
    },
    set
  ),
}));

type EditGroup = ApiState<any, any, any>;

const useEditGroup = create<EditGroup>((set) => ({
  ...getInitialState<any, any, any>({}, set),
  fetchFn: async (body) => {
    let data: any = null as any;
    let error: any = null;
    let isError = false;
    let isLoading = false;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      });
      isLoading = true;
      const res = await axiosApi({
        url: `/api/group/${body?.params?.groupId}`,
        method: "PATCH",
        data: {
          title: body?.data?.title,
          doctors: body?.data?.doctors,
        },
      });
      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
}));

type AddDoctorToGroup = ApiState<
  { groupId: string; doctors: string[] },
  any,
  any
>;

const useAddDoctorToGroup = create<AddDoctorToGroup>((set) => ({
  ...getInitialState<any, any, any>(
    {
      method: "POST",
      url: "/api/group/doctors/add",
    },
    set
  ),
}));

type DeleteDoctorFromGroup = ApiState<
  { groupId: string; doctors: string[] },
  any,
  any
>;

const useDeleteDoctorFromGroup = create<DeleteDoctorFromGroup>((set) => ({
  ...getInitialState<any, any, any>(
    {
      method: "DELETE",
      url: "/api/group/doctors/remove",
    },
    set
  ),
}));

type CreateSubGroup = ApiState<
  {
    title: string;
    groupId: string;
  },
  any,
  any
>;

const useCreateSubGroup = create<CreateSubGroup>((set) => ({
  ...getInitialState<any, any, any>(
    {
      method: "POST",
      url: "/api/subgroup/create",
    },
    set
  ),
}));

type EditSubGroup = ApiState<any, any, any>;

const useEditSubGroup = create<EditSubGroup>((set) => ({
  ...getInitialState<any, any, any>({}, set),
  fetchFn: async (body) => {
    let data: any = null as any;
    let error: any = null;
    let isError = false;
    let isLoading = false;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      });
      isLoading = true;
      const res = await axiosApi({
        url: `/api/subgroup/${body?.params?.subGroupId}`,
        method: "PATCH",
        data: {
          title: body?.data?.title,
          doctors: body?.data?.doctors,
        },
      });
      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
}));

type GetUnassignedDoctorsInGroup = ApiState<
  { subGroupId: string; doctors: string[] },
  any,
  any
>;

const useGetUnassignedDoctorsInGroup = create<GetUnassignedDoctorsInGroup>(
  (set) => ({
    ...getInitialState<{ subGroupId: string; doctors: string[] }, any, any>(
      {},
      set
    ),
    fetchFn: async (body) => {
      let data: any = null as any;
      let error: any = null;
      let isError = false;
      let isLoading = false;
      let isSuccess = false;

      try {
        set({
          isLoading: true,
          isError: false,
          isSuccess: false,
          data: null,
          error: null,
        });
        isLoading = true;
        const res = await axiosApi({
          url: `/api/group/doctors/sub-group-unassigned?groupId=${body?.params?.groupId}`,
          method: "GET",
        });
        set({ data: res.data, isSuccess: true });
        data = res.data;
        isSuccess = true;
      } catch (err: any) {
        set({ isError: true, error: err, isSuccess: false, data: null });
        isError = true;
        error = err;
        isSuccess = false;
      } finally {
        set({ isLoading: false });
        isLoading = false;
      }

      return {
        unwrap: () => {
          return { data, error, isError, isLoading, isSuccess };
        },
      };
    },
  })
);

type AddDoctorToSubGroup = ApiState<
  { subGroupId: string; doctors: string[] },
  any,
  any
>;

const useAddDoctorToSubGroup = create<AddDoctorToSubGroup>((set) => ({
  ...getInitialState<{ subGroupId: string; doctors: string[] }, any, any>(
    {},
    set
  ),
  fetchFn: async (body) => {
    let data: any = null as any;
    let error: any = null;
    let isError = false;
    let isLoading = false;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      });
      isLoading = true;
      const res = await axiosApi({
        url: `/api/subgroup/doctor/${body?.data?.subGroupId}`,
        method: "PUT",
        data: {
          doctors: body?.data?.doctors,
        },
      });
      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
}));

type DeleteDoctorFromSubGroup = ApiState<
  { subGroupId: string; doctorId: string },
  any,
  any
>;

const useDeleteDoctorFromSubGroup = create<DeleteDoctorFromSubGroup>((set) => ({
  ...getInitialState<{ subGroupId: string; doctorId: string }, any, any>(
    {},
    set
  ),
  fetchFn: async (body) => {
    let data: any = null as any;
    let error: any = null;
    let isError = false;
    let isLoading = false;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      });
      isLoading = true;
      const res = await axiosApi({
        url: `/api/subgroup/doctor/${body?.data?.subGroupId}/${body?.data?.doctorId}`,
        method: "DELETE",
      });

      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
}));

type DeleteSubGroup = ApiState<{ subGroupId: string }, any, any>;

const useDeleteSubGroup = create<DeleteSubGroup>((set) => ({
  ...getInitialState<{ subGroupId: string }, any, any>({}, set),
  fetchFn: async (body) => {
    let data: any = null as any;
    let error: any = null;
    let isError = false;
    let isLoading = false;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      });
      isLoading = true;

      const res = await axiosApi({
        url: `/api/subgroup/${body?.data?.subGroupId}`,
        method: "DELETE",
      });

      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
}));

type DeleteGroup = ApiState<{ groupId: string }, any, any>;

const useDeleteGroup = create<DeleteGroup>((set) => ({
  ...getInitialState<{ groupId: string }, any, any>({}, set),

  fetchFn: async (body) => {
    let data: any = null as any;
    let error: any = null;
    let isError = false;
    let isLoading = false;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      });
      isLoading = true;

      const res = await axiosApi({
        url: `/api/group/${body?.data?.groupId}`,
        method: "DELETE",
      });

      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
}));

const group = {
  useGetGroups,
  useGetSubGroupsByGroups,
  useGetUnassignedDoctorsInGroup,
  useCreateGroup,
  useEditGroup,
  useAddDoctorToGroup,
  useDeleteDoctorFromGroup,
  useAddDoctorToSubGroup,
  useDeleteDoctorFromSubGroup,
  useCreateSubGroup,
  useEditSubGroup,
  useDeleteSubGroup,
  useDeleteGroup,
};

export default group;
