import { useNavigate, useSearchParams } from "react-router-dom"
import ResetPasswordComponent from "../components/ResetPassword/index"
import Default from "../layouts/Default"
import { useEffect } from "react"
import api from "../store/api"
import { toast } from "react-toastify"

const OnboardPassword = () => {
  const [query, setQuery] = useSearchParams()
  const navigate = useNavigate()

  const { fetchFn: onboardPassword } = api.auth.useOnboardPassword()

  useEffect(() => {
    console.log(query.get("token"))
    if (!query.get("token")) {
      navigate("/")
    }
  }, [])

  return (
    <Default>
      <ResetPasswordComponent
        title="Let's setup your password"
        onSubmit={async (values, setLoading) => {
          const onboard = await onboardPassword({
            data: {
              password: values.password,
            },
            params: {
              onBoardToken: query.get("token") ?? "",
            },
          })

          const { isSuccess, data, isError, error } = onboard.unwrap()

          if (isSuccess) {
            toast.success(data?.message ?? "Onboarded successfully!")
            navigate("/")
          }

          if (isError) {
            toast.error(
              error?.response?.data?.message ?? "Something went wrong!"
            )
            setLoading(false)
          }
        }}
      />
    </Default>
  )
}

export default OnboardPassword
