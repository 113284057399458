// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FC, SetStateAction, Dispatch, useMemo } from "react";

interface verticalSliderprops {
  id: string;
  min?: number;
  max: number;
  number: number;
  setNumber: Dispatch<SetStateAction<number>>;
}

const NumberSlider: FC<verticalSliderprops> = ({
  id,
  min = 0,
  max,
  number,
  setNumber,
}) => {
  const numberList = useMemo(
    () => Array.from({ length: max - min + 1 }).map((_, index) => index + min),
    [max, min]
  );

  const handleMoved = (splide: any) => {
    setNumber(numberList[splide.index]);
  };

  const handlePadStart = (splide: any) => {
    return splide.toString().padStart(2, "0");
  };

  return (
    <Splide
      className="slide lg:block hidden bg-white rounded-md max-w-[3rem]"
      onMove={handleMoved}
      id={id}
      options={{
        gap: 0,
        autoplay: false,
        height: 44,
        direction: "ttb",
        type: "loop",
        perMove: 1,
        rewind: true,
        perPage: 1,
        focus: "center",
        arrows: true,
        start: number - min,
      }}
      aria-label="Number Scroll"
    >
      {numberList.map((number, index) => (
        <SplideSlide key={index}>
          <div className=" rounded h-full w-full text-center flex justify-center items-center font-medium text-sm text-black1">
            {handlePadStart(number)}
          </div>
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default NumberSlider;
