import React, { useRef, useState } from "react"
import { useOutsideClick } from "rooks"

interface ContextMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  menu: React.ReactNode
  disabled?: boolean
  onToggle?: (isOpen: boolean) => void
}

const ContextMenu = (props: ContextMenuProps) => {
  const { disabled, menu, children, onToggle, className } = props
  const [isOpen, setOpen] = useState<boolean>(false)
  const menuWrapperRef = useRef(null)
  const [alignmentCss, setAlignmentCss] = useState<string>("")

  useOutsideClick(menuWrapperRef, (e) => {
    setOpen(false)
    onToggle?.(false)
  })

  return (
    <div
      ref={menuWrapperRef}
      className={`relative ${className}`}
      onClick={(e) => {
        if (disabled) {
          return
        }

        const target = e.target as HTMLElement
        const rect = target.getBoundingClientRect()
        const rightSpace = window.innerWidth - rect.right

        if (rightSpace < 200) {
          setAlignmentCss("right-0")
        } else {
          setAlignmentCss("left-0")
        }

        e.stopPropagation()
        setOpen(true)
        onToggle?.(true)
      }}
    >
      {isOpen ? (
        <div
          className={`absolute z-[99] top-[80%] drop-shadow-md ${alignmentCss}`}
        >
          {menu}
        </div>
      ) : null}
      {children}
    </div>
  )
}

export default ContextMenu
