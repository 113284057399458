export const checkContested = (shiftRequests: any[] | undefined) => {
  if (!shiftRequests || shiftRequests.length === 0) {
    return false;
  }
  const totalFilled =
    shiftRequests[0].shifts.reduce(
      (sum: any, shift: { doctorsFilled: any }) => {
        return sum + shift.doctorsFilled;
      },
      0,
    ) ?? 0;
  const totalDoctorsRequired =
    shiftRequests[0].shifts.reduce(
      (sum: any, shift: { totalDoctorsRequired: any }) => {
        return sum + shift.totalDoctorsRequired;
      },
      0,
    ) ?? 0;
  return totalFilled + (shiftRequests?.length ?? 0) > totalDoctorsRequired;
};

export const shiftRequestFilter =
  (shiftId: string, status = "pending") =>
  (sR: { shifts: any[]; status: string }) =>
    sR.shifts.map((shift) => shift._id).includes(shiftId) &&
    (sR.status === status || status === "all");
