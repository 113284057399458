import { useEffect, useState } from "react";
import { BsCalendar2 } from "react-icons/bs";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import Cross from "../Icons/Cross";
import Input from "../Input";
import Button from "../Button";

import { useLocation } from "../../store/location.store";
import { useSeniority } from "../../store/seniority.state";
import { useModal } from "../../hooks/useModal";
import DateRangePicker from "../Calender/DateRangePicker";
import { useOpenRequestDaysMutation } from "../../store/rosterApi";
import { TIME_ZONE } from "../../constants";
import { addDay, formatDateAPI } from "../../utils/formatTime";

interface ReleaseShiftReqProps {
  calendarData?: any;
  deadline: Date | undefined;
  onSubmit?: () => void;
}

const ReleaseShiftReq = ({
  onSubmit,
  calendarData,
  deadline,
}: ReleaseShiftReqProps) => {
  const { closeModal } = useModal();

  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();

  const [
    setOpenRequestDates,
    {
      isLoading: isOpenRequestDaysLoading,
      data: openRequestDatesData,
      error: openRequestDateError,
      isSuccess: isOpenRequestDaysSuccess,
    },
  ] = useOpenRequestDaysMutation();

  useEffect(() => {
    if (isOpenRequestDaysLoading && openRequestDateError) {
      toast.error((openRequestDateError as any).message);
    }
    if (isOpenRequestDaysSuccess && openRequestDatesData) {
      onSubmit?.();
      toast.success("Successfully released shift request");
      closeModal();
    }
  }, [
    isOpenRequestDaysLoading,
    openRequestDatesData,
    openRequestDateError,
    isOpenRequestDaysSuccess,
    onSubmit,
    closeModal,
  ]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [deadlineState, setDeadline] = useState<Date | null>(deadline ?? null);

  return (
    <div className="rounded-2xl bg-white p-8 w-[658px] ">
      <div className="flex justify-between items-center py-4">
        <div></div>
        <div className="text-[#303030] col-span-11 text-center text-xl font-bold leading-8">
          RELEASE SHIFT REQUEST
        </div>
        <button
          className="col-span-1 flex justify-end"
          onClick={() => {
            closeModal();
          }}
        >
          <Cross />
        </button>
      </div>
      <div className="grid grid-cols-12 gap-x-6">
        <div className="col-span-6">
          <div className="text-[#595959] font-bold leading-5 text-sm py-4">
            Choose Date Range
          </div>
          <DateRangePicker
            onUpdate={(startDate, endDate) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            disabledDates={calendarData
              ?.filter((item: any) => item.isClosed)
              .map((v: any) =>
                DateTime.fromISO(v.date).setZone(TIME_ZONE).toJSDate(),
              )}
          />
        </div>
        <div className="col-span-6">
          <div></div>
          <div className="grid gap-y-5">
            <Input
              placeholder="Start Date"
              disabled={true}
              value={startDate ? formatDateAPI(startDate) : ""}
              icon={{
                content: <BsCalendar2 size={20} color="#67823A" />,
                position: "left",
              }}
              className="!rounded-xl"
            />
            <Input
              placeholder="End Date"
              disabled={true}
              value={endDate ? formatDateAPI(endDate) : ""}
              icon={{
                content: <BsCalendar2 size={20} color="#67823A" />,
                position: "left",
              }}
              className="!rounded-xl"
            />
          </div>
          <div className="text-[#595959] font-bold leading-5 text-sm pt-7">
            Shift Request Deadline
          </div>
          <div className="py-2 flex justify-between items-center gap-x-4">
            <Input
              placeholder="Deadline"
              disabled={false}
              type="date"
              value={
                deadlineState
                  ? (DateTime.fromJSDate(deadlineState)
                      .setZone(TIME_ZONE)
                      .toISODate() as string)
                  : undefined
              }
              onChange={(event) => {
                setDeadline(
                  DateTime.fromFormat(event.target.value, "yyyy-MM-dd")
                    .setZone(TIME_ZONE)
                    .toJSDate(),
                );
              }}
              icon={{
                content: <BsCalendar2 size={20} color="#67823A" />,
                position: "left",
              }}
              className="!rounded-xl"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Button
          disabled={isOpenRequestDaysLoading}
          onClick={() => {
            const dates = [];
            if (startDate && endDate) {
              let currentDate = startDate;
              while (currentDate <= endDate) {
                dates.push(formatDateAPI(currentDate));
                currentDate = addDay(currentDate, 1);
              }
            } else if (startDate && !endDate) {
              dates.push(formatDateAPI(startDate));
            }
            if (deadlineState && dates.length > 0) {
              setOpenRequestDates({
                dates,
                deadline: formatDateAPI(deadlineState),
                locationId: activeLocationId,
                seniority: activeSeniorityId,
                isFinalized: false,
              });
            }
          }}
          variant="primary"
          className="!h-10 !w-[140px] !justify-center !rounded-2xl"
        >
          {isOpenRequestDaysLoading ? "Loading..." : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default ReleaseShiftReq;
