import "react-tooltip/dist/react-tooltip.css";
import { useEffect, useState } from "react";
import { useDebounce } from "rooks";
import { InfinitySpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { AnimatePresence } from "framer-motion";

import Button from "../Button/index";
import ConfirmationModal from "../Modals/Confirmation";
import UploadModal from "../Modals/UploadModal";
import EditModal from "../Modals/EditDoctor";
import CreateUserModal from "../Modals/CreateUser";
import SearchBox from "../Input/SearchBox";
import { useExportDoctorsMutation } from "../../store/rosterApi";
import UserMany from "../Icons/UserMany";
import AddUser from "../Icons/AddUser";
import Clock from "../Icons/clock";
import ShiftDetailsModal from "../Modals/ShiftDetailsModal";
import EditRound2 from "../Icons/EditRound2";
import DeleteRound2 from "../Icons/DeleteRound2";
import CalendarRound2 from "../Icons/CalendarRound2";
import DoctorCard from "../Cards/DoctorCard";
import {
  useDeleteDoctorMutation,
  useGetDoctorsQuery,
} from "../../store/doctorApi";
import { useSeniority } from "../../store/seniority.state";
import { useModal } from "../../hooks/useModal";
import UserProfile from "./UserManagement/UserProfile";
import Pagination from "./UserManagement/Pagination";
import useRunOnChange from "../../hooks/runOnChange";
import useDoctorCardAnimate, { getRowCol } from "./hooks/doctorCardAnimate";

const ROWS = 7;
const COLUMNS = 5;

const UserManagementTab = () => {
  const { activeId: activeSeniorityId } = useSeniority();

  const [page, setPage] = useState<number>(1);

  const [searchQ, setSearchQ] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const handleSearch = (q: string) => {
    setPage(1);
    setSearchQ(q);
  };

  useRunOnChange(activeSeniorityId, activeSeniorityId, () => {
    setSelectedUsers([]);
    setPage(1);
  });

  const searchDebounced = useDebounce(handleSearch, 5);

  const {
    data: doctors,
    isLoading,
    refetch: refetchDoctors,
    isFetching,
  } = useGetDoctorsQuery({
    seniority: activeSeniorityId,
    page,
    max: ROWS * COLUMNS,
    q: searchQ,
  });

  const { openModal } = useModal();

  const [
    deleteDoctor,
    {
      isLoading: isDeleteDoctorLoading,
      isError: isDeleteDoctorError,
      error: deleteDoctorError,
    },
  ] = useDeleteDoctorMutation();

  const [
    exportDoctors,
    {
      isLoading: isExportDoctorsLoading,
      isError: isExportDoctorsError,
      error: exportDoctorsError,
    },
  ] = useExportDoctorsMutation();

  useEffect(() => {
    if (isExportDoctorsError) {
      toast.error((exportDoctorsError as any).message);
    }
    if (isDeleteDoctorError) {
      toast.error((deleteDoctorError as any).message);
    }
  }, [
    isExportDoctorsError,
    exportDoctorsError,
    isDeleteDoctorError,
    deleteDoctorError,
  ]);

  const selectedDoctorIndex =
    selectedUsers.length && doctors
      ? doctors?.doctors.findIndex(
          (doctor: { _id: any }) => doctor._id === selectedUsers[0]._id,
        )
      : -1;

  const { finalDoctors, profileColPos, profileRowPos, type } =
    useDoctorCardAnimate({
      selectedDoctorIndex,
      doctors: doctors?.doctors,
      enable: selectedUsers.length === 1,
      rows: ROWS,
      columns: COLUMNS,
    });

  return (
    <div className="flex flex-col pt-4 w-full h-full mx-auto relative z-[5] bg-white">
      <div className="flex items-center justify-between pb-10 relative z-[5] bg-white">
        <div className="flex items-center gap-3">
          <Button
            variant="bordered"
            className="w-min px-3 !py-1 !rounded-full h-min whitespace-nowrap text-sm"
            onClick={() => {
              exportDoctors();
            }}
          >
            Export All
          </Button>
          <Button
            variant="bordered"
            className="w-min px-3 !py-1 !rounded-full h-min whitespace-nowrap text-sm"
            onClick={() => {
              openModal(
                <CreateUserModal
                  initialSeniority={activeSeniorityId}
                  onSubmit={() => {
                    refetchDoctors();
                  }}
                />,
              );
            }}
            icon={{
              position: "left",
              content: <AddUser />,
            }}
          >
            Add User
          </Button>
          <Button
            onClick={() => {
              openModal(
                <UploadModal
                  type="user"
                  onSubmit={() => {
                    refetchDoctors();
                  }}
                />,
              );
            }}
            variant="bordered"
            className="w-min px-3 !py-1 !rounded-full h-min whitespace-nowrap text-sm"
            icon={{
              position: "left",
              content: <UserMany />,
            }}
          >
            Add Bulk User
          </Button>
          <Button
            onClick={() => {
              openModal(
                <UploadModal
                  type="shift"
                  onSubmit={() => {
                    refetchDoctors();
                  }}
                />,
              );
            }}
            variant="bordered"
            className="w-min px-3 !py-1 !rounded-full h-min whitespace-nowrap text-sm"
            icon={{
              position: "left",
              content: <Clock />,
            }}
          >
            Add Bulk Shifts
          </Button>
        </div>
        <div className="w-[30%] max-w-[400px]">
          <SearchBox
            value={searchQ}
            placeholder={"Search User..."}
            onChange={(e) => {
              searchDebounced(e.target.value);
            }}
          />
        </div>
        <div className="flex gap-x-2 items-center">
          <Button
            variant="ghost"
            className="!bg-[#D1DBAF] p-2 h-min w-min !rounded-full"
            disabled={!selectedUsers?.length || selectedUsers.length !== 1}
            title="Edit"
            onClick={() => {
              openModal(
                <EditModal
                  doctor={selectedUsers[0]}
                  onSubmit={() => {
                    refetchDoctors();
                    setSelectedUsers([]);
                  }}
                />,
              );
            }}
          >
            <EditRound2 />
          </Button>
          <Button
            variant="ghost"
            className="!bg-[#D1DBAF] p-2 h-min w-min !rounded-full"
            disabled={!selectedUsers?.length}
            title="Delete"
            onClick={() => {
              openModal(
                <ConfirmationModal
                  message={`Are you sure you want to delete selected ${selectedUsers.length} doctors?`}
                  confirmTxt="Delete All"
                  onConfirm={async (close) => {
                    const promises = selectedUsers.map((user) =>
                      deleteDoctor({
                        data: {
                          doctorId: user._id,
                        },
                      }),
                    );
                    await Promise.all(promises);
                    setSelectedUsers([]);
                    toast.success("Doctors deleted successfully");
                    close();
                  }}
                />,
              );
            }}
          >
            <DeleteRound2 />
          </Button>
          <Button
            variant="ghost"
            className="!bg-[#D1DBAF] p-2 h-min w-min !rounded-full"
            disabled={!selectedUsers?.length || selectedUsers.length !== 1}
            title="Shift Details"
            onClick={() => {
              openModal(
                <ShiftDetailsModal
                  selectedUser={selectedUsers[0]._id.toString()}
                />,
              );
            }}
          >
            <CalendarRound2 />
          </Button>
        </div>
      </div>
      <div className="rounded-lg flex-grow p-5 bg-gray6 relative z-[1]">
        {isLoading ? (
          <div className="h-full flex justify-center items-center">
            <InfinitySpin width="200" color="#67823A" />
          </div>
        ) : doctors ? (
          <div className="grid grid-cols-5 grid-rows-7 gap-5 h-full relative overflow-hidden">
            {finalDoctors.map(
              (
                doctor: {
                  user: {
                    email: string;
                    name: string;
                  };
                  experience: number;
                  efficiency: number;
                  groups: any[];
                  subGroups: any[];
                  tags: any[];
                  _id: { toString: () => string };
                },
                index: number,
              ) => {
                const { row, col } = getRowCol(COLUMNS)(index);
                return (
                  <>
                    {[1, 2, 3, 4].includes(row) &&
                    [0, 1, 2, 3].includes(col) &&
                    selectedUsers.length === 1 &&
                    profileRowPos === row &&
                    profileColPos === col ? (
                      <AnimatePresence>
                        <UserProfile
                          type={type.y}
                          email={selectedUsers[0].user.email}
                          experience={selectedUsers[0].experience}
                          efficiency={selectedUsers[0].efficiencyScore}
                          groups={selectedUsers[0].groups}
                          subGroups={selectedUsers[0].subGroups}
                          tags={selectedUsers[0].tags}
                        />
                      </AnimatePresence>
                    ) : doctor ? (
                      <div className="h-full min-h-14 col-span-1 row-span-1 relative">
                        {Boolean(
                          selectedUsers.find((user) => user._id === doctor._id),
                        ) && (
                          <>
                            <div
                              className={`absolute h-[320%] w-[205%] bg-gray6 ${
                                type.y === "top" ? "bottom-0" : "top-0"
                              } ${
                                type.x === "right" ? "left-0" : "right-0"
                              } z-[3]`}
                            ></div>
                          </>
                        )}
                        <DoctorCard
                          className="z-10 relative h-full"
                          type="user-management"
                          name={doctor.user.name}
                          doctor={doctor}
                          doctorId={doctor._id.toString()}
                          isActive={Boolean(
                            selectedUsers.find(
                              (user) => user._id === doctor._id,
                            ),
                          )}
                          isFetching={
                            isFetching ||
                            isDeleteDoctorLoading ||
                            isExportDoctorsLoading
                          }
                          onClickHandler={() => {
                            if (
                              !isFetching &&
                              !isDeleteDoctorLoading &&
                              !isExportDoctorsLoading
                            ) {
                              if (
                                selectedUsers.find(
                                  (user) => user._id === doctor._id,
                                )
                              ) {
                                setSelectedUsers([
                                  ...selectedUsers.filter(
                                    (user) => user._id !== doctor._id,
                                  ),
                                ]);
                              } else {
                                setSelectedUsers([...selectedUsers, doctor]);
                              }
                            }
                          }}
                          seniority={
                            activeSeniorityId.toString() as "1" | "2" | "3"
                          }
                        />
                      </div>
                    ) : (
                      <div className="h-full min-h-14 col-span-1 row-span-1 z-10 relative"></div>
                    )}
                  </>
                );
              },
            )}
          </div>
        ) : (
          <div>No Doctors Found</div>
        )}
      </div>
      {doctors && (
        <Pagination
          isLoading={isLoading}
          page={page}
          totalPages={doctors.totalPages}
          setPage={setPage}
          setSelectedUsers={setSelectedUsers}
        />
      )}
    </div>
  );
};

export default UserManagementTab;
