import SeniorityIcon from "../Icons/SeniorityIcon"

interface DoctorCardWithSeniorityProps {
  name: string
  skeleton?: boolean
}

const DoctorCardWithSeniority = (props: DoctorCardWithSeniorityProps) => {
  const { skeleton = false } = props
  return (
    <div className=" border-l-4 border-y-0 border-secondary h-16 p-4 rounded-lg  outer-shadow">
      <div className="flex justify-between items-center gap-x-3 h-full">
        {skeleton ? (
          <div className="w-20 h-5 bg-gray-300 rounded-md animate-pulse"></div>
        ) : (
          <div className="font-semibold">{props.name}</div>
        )}

        <div className="col-span-1">
          {skeleton ? (
            <div className="w-5 h-5 bg-gray-300 rounded-md animate-pulse"></div>
          ) : (
            <SeniorityIcon color={"#E57041"} />
          )}
        </div>
      </div>
    </div>
  )
}

export default DoctorCardWithSeniority
