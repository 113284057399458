import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState,
} from "react";

import { ResponsiveWindowType } from "../interface/responsiveWindow";
import { useSeniority } from "../store/seniority.state";

export const EstimationContext = createContext<{
  responsiveWindow: { type: ResponsiveWindowType; props: object };
  setResponsiveWindow: Dispatch<
    SetStateAction<{ type: ResponsiveWindowType; props: object }>
  >;
  monitorActiveId: string | undefined;
  setMonitorActiveId: Dispatch<SetStateAction<string | undefined>>;
  editShiftMinId: string | undefined;
  setEditShiftMinId: Dispatch<SetStateAction<string | undefined>>;
  editShiftId: string | undefined;
  setEditShiftId: Dispatch<SetStateAction<string | undefined>>;
  dataDoctorId: string | undefined;
  setDataDoctorId: Dispatch<SetStateAction<string | undefined>>;
  doctorFilterSeniority: number;
  setDoctorFilterSeniority: Dispatch<SetStateAction<number>>;
  resetStates: (activeSeniority: number) => void;
  triggerBarReset: boolean;
  setTriggerBarReset: Dispatch<SetStateAction<boolean>>;
  activeTab: "suggested" | "seniority" | "tags";
  setActiveTab: Dispatch<SetStateAction<"suggested" | "seniority" | "tags">>;
  requestTab: string | undefined;
  setRequestTab: Dispatch<SetStateAction<string | undefined>>;
}>({
  responsiveWindow: { type: "dateView", props: {} },
  setResponsiveWindow: () => undefined,
  monitorActiveId: undefined,
  setMonitorActiveId: () => undefined,
  editShiftMinId: undefined,
  setEditShiftMinId: () => undefined,
  editShiftId: undefined,
  setEditShiftId: () => undefined,
  dataDoctorId: undefined,
  setDataDoctorId: () => undefined,
  doctorFilterSeniority: 1,
  setDoctorFilterSeniority: () => undefined,
  resetStates: () => undefined,
  triggerBarReset: false,
  setTriggerBarReset: () => undefined,
  activeTab: "seniority",
  setActiveTab: () => undefined,
  requestTab: undefined,
  setRequestTab: () => undefined,
});

export const EstimationContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { activeId: activeSeniorityId } = useSeniority();

  const [responsiveWindow, setResponsiveWindow] = useState<{
    type: ResponsiveWindowType;
    props: object;
  }>({
    type: "dateView",
    props: {},
  });
  const [monitorActiveId, setMonitorActiveId] = useState<string | undefined>(
    undefined,
  );
  const [editShiftMinId, setEditShiftMinId] = useState<string | undefined>(
    undefined,
  );
  const [editShiftId, setEditShiftId] = useState<string | undefined>(undefined);
  const [requestTab, setRequestTab] = useState<string | undefined>(
    undefined,
  );

  const [dataDoctorId, setDataDoctorId] = useState<string | undefined>(
    undefined,
  );

  const [doctorFilterSeniority, setDoctorFilterSeniority] =
    useState<number>(activeSeniorityId);

  const [triggerBarReset, setTriggerBarReset] = useState(false);

  const [activeTab, setActiveTab] = useState<
    "suggested" | "seniority" | "tags"
  >("seniority");

  const resetStates = (activeSeniority: number) => {
    setResponsiveWindow({ type: "dateView", props: {} });
    setMonitorActiveId(undefined);
    setEditShiftMinId(undefined);
    setEditShiftId(undefined);
    setDataDoctorId(undefined);
    setDoctorFilterSeniority(activeSeniority);
    setTriggerBarReset((trigger) => !trigger);
  };

  return (
    <EstimationContext.Provider
      value={{
        setResponsiveWindow,
        responsiveWindow,
        monitorActiveId,
        setMonitorActiveId,
        editShiftMinId,
        setEditShiftMinId,
        editShiftId,
        setEditShiftId,
        dataDoctorId,
        setDataDoctorId,
        doctorFilterSeniority,
        setDoctorFilterSeniority,
        resetStates,
        triggerBarReset,
        setTriggerBarReset,
        activeTab,
        setActiveTab,
        requestTab,
        setRequestTab,
      }}
    >
      {children}
    </EstimationContext.Provider>
  );
};
