import React, { useMemo, useRef } from "react";
import { TIME_ZONE } from "../constants";
import { DateTime } from "luxon";
import useRightClick from "../hooks/useRightClick";
interface CalendarProps extends React.HTMLAttributes<HTMLDivElement> {
  month: number;
  year: number;
  dayClass?: string;
  onDayRender?: (day: Date) => JSX.Element;
  onClick?: (e: any) => void;
  resetDates?: () => void;
}

const Calendar = ({
  month,
  year,
  dayClass,
  onClick,
  onDayRender,
  resetDates,
  ...props
}: CalendarProps) => {
  const calendarDays = useMemo(() => {
    const startOfMonth = DateTime.fromObject(
      { month, year, day: 1 },
      { zone: TIME_ZONE },
    );
    const daysInMonth = startOfMonth.daysInMonth;
    const days = [...Array(daysInMonth).keys()].map((v) => v + 1);

    const calendar = [...Array(daysInMonth! + startOfMonth.weekday).keys()].map(
      (v) => {
        if (v < startOfMonth.weekday) {
          return null;
        }
        if (v > daysInMonth! + startOfMonth.weekday - 1) {
          return null;
        }
        return days[v - startOfMonth.weekday];
      },
    );

    return calendar;
  }, [month, year]);

  const calendarRef = useRef<HTMLDivElement | null>(null);

  useRightClick(calendarRef, () => {
    resetDates?.();
  });

  return (
    <div
      {...props}
      className={`${
        props?.className ?? ""
      } flex-grow w-full p-3 gap-6 grid grid-cols-7 this`}
      ref={calendarRef}
    >
      {calendarDays.map((day, indx) => {
        if (day) {
          const date = DateTime.fromObject(
            { day, month, year },
            { zone: TIME_ZONE },
          ).toJSDate();

          return onDayRender ? (
            onDayRender(date)
          ) : (
            <div
              key={indx}
              className={dayClass}
              onClick={(e: any) => onClick?.(e)}
            >
              {day}
            </div>
          );
        }
        return (
          <div key={indx} className="flex justify-center items-center">
            {day}
          </div>
        );
      })}
    </div>
  );
};

export default Calendar;
