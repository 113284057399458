import React, { ReactNode } from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: { position: string; content: ReactNode };
  variant?: "primary" | "ghost" | "bordered";
  className?: string;
}

const Button = (props: ButtonProps) => {
  const { icon, variant = "primary", className, ...nativeProps } = props;

  const variantClasses: Record<typeof variant, string> = {
    primary: "bg-secondary ",
    ghost: "bg-transparent",
    bordered:
      "flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary rounded-xl h-10",
  };

  return (
    <button
      {...nativeProps}
      className={`${
        variantClasses[variant]
      } w-full flex  items-center gap-x-2 rounded-md text-white h-12  font-bold disabled:bg-opacity-75 ${
        className ?? ""
      } ${
        nativeProps.disabled
          ? "cursor-not-allowed !opacity-75"
          : "cursor-pointer"
      }`}
    >
      {icon ? (icon.position === "left" ? icon.content : null) : null}
      {nativeProps.children}
      {icon ? (icon.position === "right" ? icon.content : null) : null}
    </button>
  );
};

export default Button;
