import { FC } from "react";

type CheckProps = {
  stroke?: string;
};
export const Check: FC<CheckProps> = ({ stroke }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <g id="Check 1" clip-path="url(#clip0_8019_22884)">
          <g id="Layer 2">
            <g id="checkmark">
              <path
                id="Vector"
                d="M8.71625 15.0004C8.60199 15 8.48902 14.9762 8.38437 14.9303C8.27972 14.8844 8.18562 14.8175 8.10792 14.7337L4.05792 10.4254C3.90652 10.2641 3.82542 10.0492 3.83245 9.82806C3.83949 9.60693 3.93408 9.39764 4.09542 9.24625C4.25676 9.09485 4.47163 9.01375 4.69277 9.02078C4.9139 9.02782 5.12319 9.12241 5.27458 9.28375L8.70792 12.9421L15.7162 5.27541C15.7874 5.18685 15.8758 5.11376 15.9762 5.06063C16.0766 5.00751 16.1868 4.97547 16.3001 4.96648C16.4133 4.95749 16.5272 4.97175 16.6347 5.00837C16.7422 5.045 16.8411 5.10321 16.9253 5.17945C17.0095 5.25569 17.0772 5.34833 17.1243 5.4517C17.1714 5.55506 17.1969 5.66696 17.1992 5.78053C17.2014 5.89409 17.1805 6.00692 17.1376 6.11209C17.0946 6.21726 17.0307 6.31254 16.9496 6.39208L9.33292 14.7254C9.25595 14.8107 9.16218 14.8792 9.05749 14.9265C8.9528 14.9738 8.83946 14.999 8.72458 15.0004H8.71625Z"
                fill={stroke}
              />
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_8019_22884">
            <rect
              width="20"
              height="20"
              fill={stroke}
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
