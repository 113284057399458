import {
  BaseQueryApi,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const shiftsApi = createApi({
  reducerPath: "shiftsApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  endpoints: (builder) => ({
    getShifts: builder.query<any, any>({
      queryFn: async (
        { locationId, date, seniority, range }: any,
        api: BaseQueryApi,
        extraOptions: object,
        baseQuery: (arg: string | FetchArgs) => any,
      ) => {
        const response = await baseQuery({
          url: `location/shift/${locationId}?date=${date}&seniority=${seniority}&range=${range}`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        });
        if (response.data) {
          return { data: response.data };
        } else if (response.error.data.message === "No shifts found.") {
          return { data: [] };
        }
        return { error: response.error };
      },
    }),
    createShift: builder.mutation<any, any>({
      query: ({ locationId, date, seniority, totalDoctorsRequired, time }) => ({
        url: `location/shift/${locationId}`,
        method: "POST",
        body: {
          date,
          time,
          seniority,
          totalDoctorsRequired,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateShift: builder.mutation<any, any>({
      query: ({ shiftId, totalDoctorsRequired, time, type }) => ({
        url: `location/shift/${shiftId}`,
        method: "PATCH",
        body: {
          totalDoctorsRequired,
          time,
          type,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteShift: builder.mutation<any, any>({
      query: ({ shiftId }) => ({
        url: `location/shift/${shiftId}`,
        method: "DELETE",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetShiftsQuery,
  useCreateShiftMutation,
  useUpdateShiftMutation,
  useDeleteShiftMutation,
  usePrefetch,
} = shiftsApi;
