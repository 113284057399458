import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import Button from "../Button";
import { useLocation } from "../../store/location.store";
import { DateTime } from "luxon";
import { TIME_ZONE } from "../../constants";

interface ShiftCardProps {
  onNext?: () => void;
  onPrev?: () => void;
  showNext?: boolean;
  showPrev?: boolean;
  time: {
    from: Date;
    to: Date;
  };
  skeleton?: boolean;
}

const ShiftCard = (props: ShiftCardProps) => {
  const {
    onPrev,
    onNext,
    showNext = true,
    showPrev = true,
    skeleton = true,
  } = props;
  const { activeId, locations } = useLocation();

  return (
    <div className="flex relative items-center bg-[#F1EFE6] h-36 rounded-xl w-fit shadow">
      {showPrev && !skeleton ? (
        <Button
          disabled={false}
          onClick={onPrev}
          className="absolute -left-4 top-[40%] !h-8 !w-8 bg-[#2C5339] outer-shadow "
        >
          <HiChevronLeft className="text-white w-20 h-20 ml-0.5" />
        </Button>
      ) : null}
      {showNext && !skeleton ? (
        <Button
          disabled={false}
          onClick={onNext}
          className="absolute -right-4 top-[40%] !h-8 !w-8 bg-[#2C5339] outer-shadow "
        >
          <HiChevronRight className="text-white w-20 h-20 ml-0.5" />
        </Button>
      ) : null}
      <div className="grid grid-cols-3 p-8 font-semibold">
        <div className="col-span-2 ">
          <div className="flex flex-col justify-center relative top-2">
            <div
              className={`flex space-x-2 ${
                skeleton ? "gap-x-1" : ""
              } items-center  p-0 m-0`}
            >
              {skeleton ? (
                <div className="text-xs text-[#2C5339] bg-[#2C5339] rounded-md animate-pulse uppercase">
                  {" "}
                  shift today:
                </div>
              ) : (
                <div className="text-xs text-[#2C5339] uppercase">
                  {" "}
                  shift today:
                </div>
              )}
              {skeleton ? (
                <div className="text-xs text-secondary bg-secondary rounded-md animate-pulse uppercase">
                  MONDAY, 1 JAN
                </div>
              ) : (
                <div className="text-xs text-secondary uppercase">
                  {DateTime.fromJSDate(props.time.from)
                    .setZone(TIME_ZONE)
                    .toFormat("EEEE, d MMMM")}
                </div>
              )}
            </div>
            {skeleton ? (
              <div className="font-bold flex text-[52px] text-[#2C5339] !p-0 !m-0 absolute top-1 animate-pulse">
                00:00
                <span className="bg-[#2C5339] text-white text-sm absolute px-1 py-0.5 rounded-lg -right-8 top-5">
                  {" "}
                  AM
                </span>
              </div>
            ) : (
              <div className="font-bold flex text-[52px] text-[#2C5339] !p-0 !m-0 absolute top-1">
                {DateTime.fromJSDate(props.time.from)
                  .setZone(TIME_ZONE)
                  .toFormat("hh:mm")}
                <span className="bg-[#2C5339] text-white text-sm absolute px-1 py-0.5 rounded-lg -right-8 top-5">
                  {" "}
                  {DateTime.fromJSDate(props.time.from)
                    .setZone(TIME_ZONE)
                    .toFormat("a")}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={`${skeleton ? "pl-2" : ""} col-span-1`}>
          {skeleton ? (
            <div className=" bg-white px-2.5 py-3 rounded-xl animate-pulse">
              <div className="text-2xl font-semibold bg-[#2C5339] opacity-50 rounded-lg text-[#2C5339]">
                <p>
                  <span>00</span>
                  &nbsp;
                  <span className="text-base">hours</span>
                </p>
              </div>
              <div className="font-semibold mt-2 uppercase bg-slate-500 rounded-lg text-slate-500">
                Location
              </div>
            </div>
          ) : (
            <div className=" bg-white px-2.5 py-3 rounded-xl">
              <div className="text-2xl font-semibold text-[#2C5339]">
                <p>
                  <span>
                    {
                      DateTime.fromJSDate(props.time.to)
                        .setZone(TIME_ZONE)
                        .diff(
                          DateTime.fromJSDate(props.time.from).setZone(
                            TIME_ZONE,
                          ),
                          "hours",
                        ).hours
                    }
                  </span>
                  &nbsp;
                  <span className="text-base">hours</span>
                </p>
              </div>
              <div className="font-semibold uppercase text-[#2C5339]">
                {(locations.find((l: any) => l.id === activeId) as any)?.label}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShiftCard;
