import Grid from "../Icons/Grid";
import Monitor from "../Icons/Monitor";
import { Minus } from "../Icons/Minus";
import { Add } from "../Icons/Add";
import Delete from "../Icons/Delete";
import { motion, AnimatePresence } from "framer-motion";
import { useUpdateShiftMutation } from "../../store/shiftsApi";
import {
  useAssignRandomnMutation,
  useDeleteScheduleMutation,
} from "../../store/rosterApi";
import { useEstimationContext } from "../../hooks/useEstimationContext";
import { formatTime } from "../../utils/formatTime";
import useHandleSuccessErrors from "../../hooks/handleSuccessErrors";

type ShiftCardProps = {
  time: { to: string; from: string };
  type: "normal" | "standBy" | "onCall";
  slots: any[];
  className?: string;
  windowType: "primary" | "secondary" | "responsive";
  shiftId: string;
  isFetching: boolean;
  refetchShifts: () => Promise<void>;
  refetchDoctors: () => Promise<void>;
  requestsStatus?: "no-requests" | "requests" | "contested";
  requestHandler?: () => void
};

const getActiveColor = (type: "primary" | "secondary" | "responsive") => {
  if (type === "primary") {
    return "bg-secondary text-white";
  }
  if (type === "secondary") {
    return "bg-yellow2 text-black";
  }
  if (type === "responsive") {
    return "bg-blue3";
  }
};

const getClassName = (type: "primary" | "secondary" | "responsive") => {
  if (type === "primary") {
    return "stroke-white group-hover:stroke-secondary fill-white";
  }
  if (type === "secondary") {
    return "stroke-black1 group-hover:stroke-secondary fill-black1";
  }
  if (type === "responsive") {
    return "stroke-black1 group-hover:stroke-secondary fill-black1";
  }
};

const getIconColor = (
  type: "primary" | "secondary" | "responsive",
  isActive = false,
) => {
  if (type === "primary") {
    return isActive ? "#67823A" : "white";
  }
  if (type === "secondary") {
    return isActive ? "#67823A" : "black";
  }
  if (type === "responsive") {
    return isActive ? "#67823A" : "black";
  }
};

const ShiftCard = ({
  time,
  type,
  className,
  windowType,
  slots,
  shiftId,
  isFetching,
  refetchShifts,
  refetchDoctors,
  requestsStatus = "no-requests",
  requestHandler
}: ShiftCardProps) => {
  const {
    setResponsiveWindow,
    responsiveWindow,
    monitorActiveId,
    setMonitorActiveId,
    editShiftId,
    setEditShiftId,
    setActiveTab,
    activeTab,
    setDataDoctorId,
  } = useEstimationContext();

  const [
    updateShift,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateShiftMutation();

  const [
    deleteSchedule,
    {
      isLoading: isDeleteScheduleLoading,
      isSuccess: isDeleteScheduleSuccess,
      isError: isDeleteScheduleError,
      error: scheduleError,
    },
  ] = useDeleteScheduleMutation();

  const [
    assignRandomn,
    {
      isLoading: isAssignRandomLoading,
      isSuccess: isAssignSuccess,
      isError: isAssignError,
      error: assignError,
    },
  ] = useAssignRandomnMutation();

  useHandleSuccessErrors({
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    error: updateError,
    successMessage: "Shift update successfull",
    successFunction: async () => {
      await refetchShifts();
    },
  });

  useHandleSuccessErrors({
    isSuccess: isDeleteScheduleSuccess,
    isError: isDeleteScheduleError,
    error: scheduleError,
    successMessage: "Schedule deleted successfully",
    successFunction: async () => {
      await Promise.all([refetchShifts(), refetchDoctors()]);
    },
  });

  useHandleSuccessErrors({
    isSuccess: isAssignSuccess,
    isError: isAssignError,
    error: assignError,
    successMessage: "Schedules auto assigned.",
    successFunction: async () => {
      await Promise.all([refetchShifts(), refetchDoctors()]);
    },
  });

  const handleAdd = async () => {
    if (slots.length < 8) {
      await updateShift({ shiftId, totalDoctorsRequired: slots.length + 1 });
    }
  };

  const handleRemove = async () => {
    if (slots.length > slots.filter((slot) => slot.schedule).length) {
      await updateShift({ shiftId, totalDoctorsRequired: slots.length - 1 });
    }
  };

  const isLoading =
    isUpdateLoading ||
    isDeleteScheduleLoading ||
    isAssignRandomLoading ||
    isFetching;

  return (
    <>
      <div className={`relative ${className}`}>
        <div
          className={`${
            editShiftId === shiftId ? "rounded-t-xl" : " rounded-xl"
          }  p-3 bg-white ${isLoading ? "animate-pulseFast" : ""}`}
        >
          <div className="relative">
            {editShiftId === shiftId ? (
              <>
                <button
                  disabled={isLoading}
                  className={`absolute cursor-pointer top-0 bottom-0 my-auto h-fit left-2.5 rounded-sm p-0.5 ${
                    monitorActiveId === shiftId ? "bg-white" : ""
                  }`}
                  onClick={() => {
                    setDataDoctorId(undefined);
                    setMonitorActiveId(monitorActiveId ? undefined : shiftId);
                    setResponsiveWindow(
                      responsiveWindow.type === "shiftView"
                        ? { type: "dateView", props: {} }
                        : { type: "shiftView", props: { shiftId } },
                    );
                  }}
                >
                  <Monitor
                    fill={getIconColor(windowType, monitorActiveId === shiftId)}
                  />
                </button>
                <button
                  disabled={isLoading}
                  className={`absolute cursor-pointer top-0 bottom-0 my-auto h-fit right-2.5 p-0.5`}
                  onClick={async () => {
                    await assignRandomn({ shiftId });
                  }}
                >
                  <Grid
                    className={isAssignRandomLoading ? "animate-spin" : ""}
                    fill={getIconColor(windowType)}
                  />
                </button>
              </>
            ) : null}
            {editShiftId !== shiftId && requestsStatus !== "no-requests" ? (
              <>
                <button
                  disabled={isLoading}
                  className={`absolute cursor-pointer top-0 bottom-0 my-auto h-fit right-2.5 rounded-sm p-0.5 ${
                    monitorActiveId === shiftId ? "bg-white" : ""
                  }`}
                  onClick={() => {
                    requestHandler?.()
                  }}
                >
                  <div className={`rounded-full h-3 w-3 ${requestsStatus === "requests" ? "bg-teal3" :"bg-maroon2"}`} />
                </button>
              </>
            ) : null}
            <button
              onClick={() => {
                if (editShiftId && editShiftId !== shiftId) {
                  setActiveTab("suggested");
                  setEditShiftId(shiftId);
                  setMonitorActiveId(undefined);
                  if (responsiveWindow.type === "shiftView") {
                    setResponsiveWindow({ type: "dateView", props: {} });
                  }
                } else if (editShiftId && editShiftId === shiftId) {
                  if (activeTab === "suggested") {
                    setActiveTab("seniority");
                  }
                  setEditShiftId(undefined);
                  setMonitorActiveId(undefined);
                  if (responsiveWindow.type === "shiftView") {
                    setResponsiveWindow({ type: "dateView", props: {} });
                  }
                } else {
                  setActiveTab("suggested");
                  setEditShiftId(shiftId);
                }
              }}
              disabled={isAssignRandomLoading}
              className={`header w-full px-6 py-2 flex justify-center items-center text-xs font-medium rounded-lg transition-all ${
                editShiftId === shiftId
                  ? getActiveColor(windowType)
                  : " bg-[#f2f2f2] text-black1"
              }`}
            >
              {type !== "normal" && (
                <div
                  className={`text-[10px] font-medium text-white ${
                    editShiftId !== shiftId
                      ? type === "onCall"
                        ? "bg-orange4"
                        : "bg-blue5"
                      : ""
                  } px-2 py-0.5 rounded-lg absolute ${
                    editShiftId === shiftId ? "left-7" : "left-1"
                  }`}
                >
                  {type === "onCall" ? "on call" : "standby"}
                </div>
              )}
              {`${formatTime(time.from)} - ${formatTime(time.to)}`}
            </button>
          </div>
          <ul className="max-w-md mt-1 mx-auto">
            <AnimatePresence>
              {slots.length === 0 ? (
                <motion.li
                  key="noDataTransition"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                  className={`pb-0.5 pt-1.5 border-x-0 border-t-0 border-b-[#BDBDBD] transition-opacity`}
                >
                  <div className="font-medium text-sm p-1 text-black2 text-center">
                    No Data
                  </div>
                </motion.li>
              ) : null}
              {slots.map((slot, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                  className={`pb-0.5 pt-1.5 cursor-pointer ${
                    index === slots.length - 1 ? "" : "border-[0.4px] "
                  } border-x-0 border-t-0 border-b-[#BDBDBD] `}
                >
                  {slot.schedule ? (
                    <div
                      className={`relative group font-medium  text-sm ${
                        index == slots.length - 1 ? " pt-2" : "p-1"
                      } text-black2 text-center`}
                    >
                      {slot.schedule.doctor.user.name}
                      {editShiftId === shiftId && !isLoading ? (
                        <div
                          className={`group-hover:block absolute hidden ${
                            index == slots.length - 1 ? "bottom-0" : "bottom-1"
                          }  rounded  p-1 my-auto right-0  `}
                          onClick={async () =>
                            await deleteSchedule({
                              scheduleId: slot.schedule._id,
                            })
                          }
                        >
                          <Delete
                            className="h-3.5 w-3.5"
                            stroke="stroke-pink1 transition duration-500"
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div
                      className={`font-medium text-sm ${
                        index == slots.length - 1 ? " pt-2" : "p-1"
                      } text-black2 text-center flex justify-center items-center`}
                    >
                      <Add stroke="stroke-black2" className="h-4 w-4" />
                    </div>
                  )}
                </motion.li>
              ))}
            </AnimatePresence>
          </ul>
        </div>
        {editShiftId === shiftId ? (
          <div
            className={`${getActiveColor(
              windowType,
            )} py-1.5 px-5 rounded-b-xl transition-all`}
          >
            <div className="flex items-center text-xs justify-between">
              <button
                className="text-white p-0.5 cursor-pointer group hover:bg-white rounded-sm transition-colors duration-500"
                onClick={() => handleRemove()}
              >
                <Minus
                  className="h-4 w-4"
                  stroke={`${getClassName(
                    windowType,
                  )}stroke-white group-hover:stroke-secondary transition duration-500`}
                />
              </button>
              <div className=" my-auto  font-medium">
                {slots.reduce((count, slot) => {
                  if (slot.schedule) {
                    return count + 1;
                  }
                  return count;
                }, 0)}
                /{slots.length}
              </div>
              <button
                className="text-white p-0.5 cursor-pointer group hover:bg-white rounded-sm transition-colors duration-500"
                onClick={() => handleAdd()}
              >
                <Add
                  className="h-4 w-4  "
                  stroke={`${getClassName(windowType)} transition duration-500`}
                />
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ShiftCard;
