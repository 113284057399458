import React, { FC, useState } from "react";
import Input from "../Input/index";
import { HiSearch } from "react-icons/hi";
import Button from "../Button/index";
import api from "../../store/api";
import { useFormik } from "formik";
import { useSeniority } from "../../store/seniority.state";
import { toast } from "react-toastify";
import { useModal } from "../../hooks/useModal";
import { useOutsideClick } from "rooks";
import DoctorShiftCard from "../Cards/DoctorShiftCard";
import Badge from "../Icons/Badge";
import Typography from "../Typography";
import { z } from "zod";
import { getSeniority } from "../../utils/fetchSeniorityColor";
import defaultAvatar from "../../assets/images/avatar-default-light.webp";

interface EditGroupForm {
  title: string;
  doctors: string[];
  subGroupId: string;
}

interface CreateSubGroupModalProps {
  subGroup: any;
  unassignedDoctorsInSubGroup: any[];
  onSuccess?: () => void;
  isSubGroup?: boolean;
}

const EditSubGroup: FC<CreateSubGroupModalProps> = (props) => {
  const { onSuccess, subGroup, unassignedDoctorsInSubGroup } = props;

  const { fetchFn: editSubGroup, isLoading } = api.group.useEditSubGroup();

  const [selectedDoctors, setSelectedDoctors] = useState<any[]>(
    subGroup?.doctors,
  );
  const [searchQuery, setSearchQuery] = useState<string>("");

  const toggleDoctor = (doctor: any) => {
    if (selectedDoctors.find((doc) => doctor._id === doc._id)) {
      setSelectedDoctors(
        selectedDoctors.filter((doc) => doc._id !== doctor._id),
      );
      formik.setFieldValue(
        "doctors",
        selectedDoctors
          .filter((doc) => doc._id !== doctor._id)
          .map((doc) => doc._id),
      );
    } else {
      setSelectedDoctors([...selectedDoctors, doctor]);
      formik.setFieldValue(
        "doctors",
        [...selectedDoctors, doctor].map((doc) => doc._id),
      );
    }
  };

  const { closeModal } = useModal();
  const [expand, setExpand] = useState(false);

  const searchWrapper = React.useRef<HTMLDivElement>(null);

  useOutsideClick(searchWrapper, () => {
    console.log("Clicked outside");
    setExpand(false);
  });

  const { activeId: activeSeniorityId } = useSeniority();
  const formik = useFormik<EditGroupForm>({
    initialValues: {
      title: subGroup.title,
      doctors:
        subGroup?.doctors.map((doctor: { _id: any }) => doctor._id) ?? [],
      subGroupId: subGroup._id,
    },
    validate: (values) => {
      const schema = z.object({
        title: z.string().nonempty("Title is required"),
        doctors: z
          .array(z.string())
          .min(1)
          .nonempty("Atleast one doctor is required"),
        // groupId: z.string().nonempty("Group is required"),
      });

      const result = schema.safeParse(values);
      if (!result.success) {
        console.log(result.error);
        return Object.fromEntries(
          result.error.errors.map((err) => [err.path[0], err.message]),
        );
      }
      return {};
    },
    onSubmit: async (values) => {
      const resp = await editSubGroup({
        params: { subGroupId: values.subGroupId },
        data: { title: values.title, doctors: values.doctors },
      });

      const { isSuccess, isError, error } = resp.unwrap();

      if (isSuccess) {
        toast.success("SubGroup updated successfully");
        onSuccess?.();
      }

      if (isError) {
        toast.error(error?.response?.data?.message);
      }
      closeModal();
    },
  });

  return (
    <div className="bg-white  z-50 flex flex-col gap-6 !w-screen outer-shadow max-w-screen-2xl px-8 py-6 mx-auto rounded-xl h-[680px]">
      <div className="text-xl uppercase text-black1 font-semibold text-center ">
        Edit {subGroup.title}
      </div>

      <div className="grid grid-cols-3 gap-16">
        <div className="col-span-1 space-y-3">
          <div className="font-bold text-sm text-black2">Sub Group Name</div>
          <Input
            type="text"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.title ? formik.errors.title : ""}
            placeholder="Enter Subgroup Name (Ex: Nurse)"
            className="!text-sm  placeholder:text-sm !rounded-2xl h-11 !border-2  !focus:ring-0 !placeholder:text-sm"
          />
        </div>
        <div className="col-span-1 space-y-3">
          <div className="font-bold text-sm text-black2">
            Choose Users For Sub Group
          </div>
          <div ref={searchWrapper} className="relative">
            <Input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onFocus={() => setExpand(true)}
              icon={{
                content: (
                  <div className="h-11 w-11 bg-secondary rounded-full flex justify-center items-center -mt-3 -mr-3">
                    {" "}
                    <HiSearch size={20} className="text-white" />
                  </div>
                ),
                // content: <HiSearch size={20} />,
                position: "right",
              }}
              placeholder="Search People..."
              className="!text-sm placeholder:text-sm !rounded-full !border-2 !placeholder:text-sm"
            />
            {expand ? (
              <div className="absolute top-full z-50 p-2 rounded drop-shadow-md left-0 w-full bg-white max-h-80 overflow-y-auto">
                {/* {isDoctorsLoading ? (
                  <div className="flex flex-col justify-center items-center">
                    <MutatingDots
                      height={130}
                      width="100"
                      color="#67823A"
                      secondaryColor="#67823A"
                      radius="12.5"
                      ariaLabel="mutating-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : null} */}
                {!false &&
                  [...subGroup.doctors, ...unassignedDoctorsInSubGroup]
                    ?.filter((doctor) =>
                      doctor.user.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                    )
                    .map((doctor: any) => (
                      <DoctorShiftCard
                        tags={doctor?.tags}
                        key={doctor._id}
                        onClick={() => {
                          toggleDoctor(doctor);
                        }}
                        variant="time"
                        selected={
                          !!selectedDoctors.find(
                            (doc) => doc._id === doctor._id,
                          )
                        }
                        name={doctor.user.name}
                        icon={
                          <Badge
                            height={24}
                            width={24}
                            className="absolute top-0 right-0"
                            varient={getSeniority(activeSeniorityId)}
                          />
                        }
                        image={defaultAvatar}
                      />
                    ))}
                {!false &&
                [...subGroup.doctors, ...unassignedDoctorsInSubGroup].length ? (
                  <div className="flex flex-col justify-center items-center min-h-72">
                    <Typography tag="p" className="font-semibold text-black3">
                      No doctors found.
                    </Typography>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-span-1"></div>
      </div>
      <div className="grid grid-cols-4 gap-6 max-h-[70%] overflow-auto pl-2 pr-6 py-1">
        {[...subGroup.doctors, ...unassignedDoctorsInSubGroup]
          // Array(100).fill({ _id: '', user: { name: '' } }).
          .map((doctor: any) => (
            <DoctorShiftCard
              tags={doctor?.tags}
              key={doctor._id}
              onClick={() => {
                toggleDoctor(doctor);
              }}
              variant="experience"
              experienceLevel={doctor.experience}
              efficiencyScore={doctor.efficiencyScore}
              selected={!!selectedDoctors.find((doc) => doc._id === doctor._id)}
              name={doctor.user.name}
              icon={
                <Badge
                  height={24}
                  width={24}
                  className="absolute top-0 right-0"
                  varient={getSeniority(activeSeniorityId)}
                />
              }
              image={defaultAvatar}
            />
          ))}
      </div>
      <div className="flex items-center mt-auto justify-center gap-8 max-w-md mx-auto w-full">
        <Button
          onClick={closeModal}
          disabled={isLoading}
          className="flex justify-center px-6 !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl !h-11"
        >
          Cancel
        </Button>
        <Button
          onClick={(e: any) => formik.handleSubmit(e)}
          className="flex justify-center whitespace-nowrap px-6 text-center !rounded-xl !h-11"
          disabled={isLoading}
        >
          {isLoading ? "Updating..." : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default EditSubGroup;
