import { useOutsideClick } from "rooks"
import innovation from "../../assets/images/Innovation.gif"
import { useRef } from "react"

const StartAssignmentModal = () => {
  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick(ref, (e) => {
    e.stopPropagation()
    e.preventDefault()
  })

  return (
    <div
      ref={ref}
      className="flex bg-white flex-col w-full gap-6 outer-shadow max-w-md px-8 py-6 mx-auto rounded-xl"
    >
      <div className="flex justify-center items-center w-full">
        <img src={innovation} className="!w-[120px]" />
      </div>
      <div className="font-DM font-bold text-xl text-center text-[#323A46]">
        Hold On Tight!
      </div>
      <div className="font-DM font-bold text-sm text-center text-[#64748B]">
        We’re making the magic happen.
      </div>
    </div>
  )
}

export default StartAssignmentModal
