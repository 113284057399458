import React, { FC, useEffect } from "react";
import Edit from "./Icons/Edit";
import Delete from "./Icons/Delete";
import { BsPerson } from "react-icons/bs";
import { FaRegEnvelope } from "react-icons/fa";
import EditAdminUserModal from "../components/Modals/EditAdminUser";
import Password from "../components/Modals/Password";
import { useModal } from "../hooks/useModal";
import {
  useDeleteAdminUserMutation,
  useSetOrganisationAdminUserActiveInactiveMutation,
} from "../store/organisationApi";
import { toast } from "react-toastify";

interface organizationProps {
  name: string;
  email: string;
  isActive: boolean;
  userId: string;
  reloadUsers: () => void;
}
const OrganizationUserCard: FC<organizationProps> = (props) => {
  const { email, isActive, name, userId, reloadUsers } = props;

  const { openModal } = useModal();

  const [
    toggleOrganisationAdminUserActive,
    { isLoading, data, error, isSuccess },
  ] = useSetOrganisationAdminUserActiveInactiveMutation();

  const [
    deleteAdminUser,
    {
      isLoading: isDeleteLoading,
      data: deleteData,
      error: deleteError,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteAdminUserMutation();

  useEffect(() => {
    if (isDeleteSuccess) {
      reloadUsers();
      toast.success("Super Admin Deleted !");
    }
    if (deleteError) {
      toast.error((deleteError as any).data.message ?? "Something went wrong");
    }
  }, [isDeleteSuccess, deleteError]);

  return (
    <div className="outer-shadow rounded-md w-full p-3">
      <div className="flex justify-between items-center pb-2.5 border-b">
        <div className="font-semibold flex items-center gap-2">
          <div className="font-bold border-2 rounded-full p-1.5 border-green3">
            <BsPerson className="h-5 w-5 text-green3" />
          </div>
          {name}
        </div>
        <div className="flex items-center justify-end gap-x-2">
          <span
            className="border-2 rounded-full border-green3 p-1.5 cursor-pointer"
            onClick={() =>
              openModal(
                <EditAdminUserModal
                  userId={userId}
                  initialValues={{ name, email }}
                  onSubmit={(password) => {
                    if (password) {
                      openModal(<Password password={password} />);
                    }
                    reloadUsers();
                  }}
                />
              )
            }
          >
            <Edit />
          </span>
          <span
            className="border-2 rounded-full border-green3 p-1.5 cursor-pointer"
            onClick={async () => {
              await deleteAdminUser({ userId });
            }}
          >
            <Delete />
          </span>
        </div>
      </div>
      <div className="flex justify-between items-center pt-2.5 ">
        <div className="flex items-center justify-end gap-3">
          <FaRegEnvelope className="h-4 w-4 text-green3" />
          <div className="text-sm font-medium"> {email}</div>
        </div>
        <div
          className="font-medium text-sm flex items-center gap-2 cursor-pointer"
          onClick={async () => {
            await toggleOrganisationAdminUserActive({ userId });
            reloadUsers();
          }}
        >
          <span
            className={` rounded-full ring ${
              isActive
                ? "ring-green-500 bg-green-500"
                : "ring-red-500 bg-red-500"
            }  p-1.5 outer-shadow h-4 w-4 `}
          ></span>
          {isActive ? "Active" : "In Active"}
        </div>
      </div>
    </div>
  );
};

export default OrganizationUserCard;
