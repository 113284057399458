import { InfinitySpin } from "react-loader-spinner";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HiCalendar } from "react-icons/hi";
import { toast } from "react-toastify";

import NavTab from "../../components/NavTabs";
import { useSeniority } from "../../store/seniority.state";
import Calendar from "../../components/Calendar";
import CalenderCard from "../../components/Calender/CalenderCard";
import { useLocation } from "../../store/location.store";
import CalendarSwitcher from "../../components/CalendarSwitcher";
import { useCalendar } from "../../store/calendar.state";
import Button from "../../components/Button";
import { useModal } from "../../hooks/useModal";
import Typography from "../../components/Typography";
import NavBar from "../../components/NavBar";
import SuccessModal from "../../components/Modals/SuccessModal";
import { useAutoAssignmentMutation } from "../../store/rosterApi";
import StartAssignmentModal from "../../components/Modals/StartAssignmentModal";
import ReleaseShiftReq from "../../components/Modals/ReleaseShiftReq";
import FinalisedRosterRelease from "../../components/Modals/FinalisedRosterRelease";
import { TIME_ZONE } from "../../constants";
import SideBarLayout from "../../layouts/Sidebar";
import {
  useGetCalendarQuery,
  useGetLocationsQuery,
} from "../../store/locationApi";

const Dashboard = () => {
  const {
    activeId: activeSeniorityId,
    setActiveId,
    seniorities,
  } = useSeniority();

  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const {
    activeId: activeLocationId,
    locations,
    setActiveId: setActiveLocationId,
  } = useLocation();

  const { isSuccess, isLoading: isLocationLoading } = useGetLocationsQuery({
    seniority: 4,
    all: true,
  });

  const [month, setMonth] = useCalendar((state) => [
    state.month,
    state.setMonth,
  ]);

  const { openModal, closeModal } = useModal();

  const [year, setYear] = useCalendar((state) => [state.year, state.setYear]);

  const {
    isLoading,
    data: calendarData,
    refetch,
  } = useGetCalendarQuery(
    {
      locationId: activeLocationId.toString(),
      seniority: Number(activeSeniorityId),
      month: month,
      year,
    },
    { skip: !activeLocationId },
  );

  useEffect(() => {
    if (searchParams.get("locationId") !== null) {
      setActiveLocationId(searchParams.get("locationId")!);
    } else if (locations.length) {
      setActiveLocationId(locations[0]._id);
    }
  }, [locations, searchParams]);

  const [autoAssign, { isLoading: isAutoAssignmentLoading }] =
    useAutoAssignmentMutation();

  return (
    <SideBarLayout isCalendarLoading={isLoading}>
      <section className="flex-grow flex flex-col items-center px-10 overflow-y-auto">
        <NavBar />
        <div className="flex w-full p-4">
          <CalendarSwitcher
            disabledLeft={
              isLoading ||
              !activeLocationId ||
              !activeSeniorityId ||
              !calendarData
            }
            disabledRight={
              isLoading ||
              !activeLocationId ||
              !activeSeniorityId ||
              !calendarData
            }
            month={month}
            year={year}
            onNext={() => {
              if (month === 11) {
                setMonth(0);
                setYear(year + 1);
                return;
              }
              setMonth(month + 1);
            }}
            onPrev={() => {
              if (month === 0) {
                setMonth(11);
                setYear(year - 1);
                return;
              }
              setMonth(month - 1);
            }}
          />
        </div>
        <div className="w-full flex justify-between">
          <div></div>
          <NavTab
            tabs={seniorities}
            activeId={activeSeniorityId}
            tabType="neumorphism"
            onChange={(id) => {
              setActiveId(id);
            }}
          />
          <div className="flex items-center gap-x-3">
            <Button
              variant="bordered"
              className="w-min whitespace-nowrap px-3"
              onClick={() => {
                openModal(
                  <FinalisedRosterRelease
                    onSubmit={async () => {
                      await refetch();
                    }}
                    calendarData={calendarData.calendar}
                  />,
                );
              }}
            >
              Finalized Roster Release
            </Button>
            {calendarData &&
            calendarData.calendar.every((c: any) => {
              if (c.isClosed) {
                return true;
              }
              if (c.summary) {
                return true;
              }
              return false;
            }) ? (
              <Button
                variant="bordered"
                className="w-min whitespace-nowrap px-3"
                onClick={() => {
                  const autoAssignPromise = autoAssign({
                    location: activeLocationId,
                    seniority: Number(activeSeniorityId),
                    month,
                    year,
                  });
                  openModal(<StartAssignmentModal />);
                  autoAssignPromise
                    .then(() => {
                      closeModal();
                      openModal(
                        <SuccessModal
                          message="Automatic estimation successful!"
                          title="Auto Assignment"
                          isLoading={false}
                        />,
                      );
                      refetch();
                    })
                    .catch((err) => {
                      console.log(err);
                      toast.error(
                        err?.response?.data?.message ?? "Something went wrong",
                      );
                    });
                }}
              >
                Roster Automation
              </Button>
            ) : null}
            <Button></Button>
            <Button
              disabled={
                isLoading ||
                !activeLocationId ||
                !activeSeniorityId ||
                !calendarData
              }
              className="w-min p-3 flex gap-x-3 items-center whitespace-nowrap"
              onClick={() => {
                openModal(
                  <ReleaseShiftReq
                    onSubmit={async () => {
                      await refetch();
                    }}
                    calendarData={calendarData.calendar}
                    deadline={
                      calendarData.deadline
                        ? DateTime.fromISO(calendarData.deadline)
                            .setZone(TIME_ZONE)
                            .toJSDate()
                        : undefined
                    }
                  />,
                );
              }}
            >
              <HiCalendar className="scale-150" /> Release Shift Request
            </Button>
          </div>
        </div>
        {isLocationLoading && (
          <div className="flex justify-center items-center h-screen">
            <div>
              <InfinitySpin width="62%" color="#67823A" />
            </div>
          </div>
        )}
        {isSuccess && locations.length && activeLocationId !== "null" ? (
          <Calendar
            month={month}
            year={year}
            onDayRender={(date) => {
              const dateTime = DateTime.fromJSDate(date).setZone(TIME_ZONE);
              const day = dateTime.toFormat("EEE");
              const dateFormatted = dateTime.toFormat("dd MMM");
              const data = calendarData?.calendar.find(
                (item: { date: string }) =>
                  dateTime.equals(
                    DateTime.fromISO(item.date).setZone(TIME_ZONE),
                  ),
              );

              const dateHash = window.btoa(date.getTime().toString());
              const summary = data?.summary;

              return (
                <CalenderCard
                  key={date.getTime()}
                  onClick={() => {
                    if (data?.isClosed) {
                      return;
                    }
                    navigate(
                      `/dashboard/${dateHash}?locationId=${activeLocationId}`,
                    );
                  }}
                  skeleton={
                    isLoading || !activeLocationId || !activeSeniorityId
                  }
                  isFinalized={data?.isFinalized}
                  variant={data?.allowRequestForDay ? "purple" : "primary"}
                  date={date}
                  monthName={dateFormatted}
                  isEstimated={!!data?.summary}
                  isDisabled={data?.isClosed}
                  day={day}
                  summary={summary}
                />
              );
            }}
          />
        ) : null}
        {isSuccess && !locations.length ? (
          <div className="flex flex-col justify-center flex-grow w-full items-center">
            <Typography tag="h3" className="text-black1">
              No locations found. Please add a location to view calendar.
            </Typography>
          </div>
        ) : null}
      </section>
    </SideBarLayout>
  );
};

export default Dashboard;
