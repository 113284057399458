import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const doctorApi = createApi({
  reducerPath: "doctorApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  endpoints: (builder) => ({
    getDoctors: builder.query<any, any>({
      query: ({ seniority, page, max, q }) => ({
        url: `/doctor/fetch?seniority=${seniority}${
          page ? `&page=${page}` : ""
        }${max ? `&max=${max}` : ""}${q ? `&q=${q}` : ""}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteDoctor: builder.mutation<any, any>({
      query: ({ doctorId }) => ({
        url: `/doctor/${doctorId}`,
        method: "DELETE",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getTags: builder.query<any, any>({
      query: ({ q }) => ({
        url: `/doctor/tags${q ? "?q=${q}" : ""}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const { useGetDoctorsQuery, useDeleteDoctorMutation, useGetTagsQuery } =
  doctorApi;
