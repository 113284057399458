export const getDateFromHash = (dateHash: string): Date =>
  new Date(Number(window.atob(dateHash)));


export function convertToAmPm(dateString: string): string { 
  const date = new Date(dateString);

  return date.toLocaleString('en-US', {
    hour: 'numeric', 
    minute: 'numeric',
    hour12: true
  });
}   