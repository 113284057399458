import { useState } from "react";
import ChatBubble from "../Icons/ChatBubble";
import RoundedCancel from "../Icons/RoundedCancel";
import RoundedTick from "../Icons/RoundedTick";
import { AnimatePresence, motion } from "framer-motion";

type UserRequestCardProps = {
  reason?: string;
  name: string;
  status: "completed" | "rejected" | "pending";
  rejectHandler: () => Promise<void>;
  acceptHandler: () => Promise<void>;
  isLoading: boolean;
  seniority: number;
  locationLabel?: string;
  isDisabled?: boolean;
};

const bgHover = {
  "3": "bg-[#DF9C28]",
  "2": "bg-[#84ACE2]",
  "1": "bg-[#E57041]",
};

const UserRequestCard = ({
  reason,
  name,
  status,
  acceptHandler,
  rejectHandler,
  isLoading,
  seniority,
  locationLabel,
  isDisabled = false,
}: UserRequestCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="relative">
      {isExpanded && (
        <div className="absolute -top-[75px] bg-gray6 w-full h-[130px] z-[5]"></div>
      )}
      <div
        className={`${
          isExpanded ? "rounded-t-xl shadow-bottomGrey" : "rounded-xl"
        } bg-white pl-4 pr-1 flex justify-between relative z-10 ${
          isLoading ? "animate-pulseFast" : ""
        }`}
      >
        <div
          className={`flex my-3 items-center ${
            !isDisabled ? "cursor-pointer" : ""
          }`}
          onClick={() => {
            if (!isDisabled) {
              setIsExpanded(!isExpanded);
            }
          }}
        >
          <div
            className={`relative -left-6 rounded-l-lg ${
              bgHover[seniority.toString() as "1" | "2" | "3"]
            } w-2 h-8`}
          ></div>
          <div className="font-semibold leading-0">{name}</div>
          {locationLabel && (
            <div className="bg-secondary text-white p-1 rounded text-xs font-bold ml-5">
              {locationLabel}
            </div>
          )}
        </div>
        {!isExpanded && (
          <div
            className={
              "flex flex-grow flex-row-reverse group overflow-hidden"
            }
          >
            {(status === "completed" || status === "rejected") && (
              <div
                className={`${
                  !isDisabled ? "group-hover:hidden" : ""
                } my-1 ml-1`}
              >
                {status === "completed" && <RoundedTick active={true} />}
                {status === "rejected" && <RoundedCancel active={true} />}
              </div>
            )}
            {reason && (
              <div
                className={`${
                  !isDisabled ? "group-hover:hidden" : ""
                } my-[5px]`}
              >
                <ChatBubble />
              </div>
            )}
            {!isDisabled && (
              <>
                {status !== "completed" && (
                  <div
                    className="cursor-pointer hidden group-hover:flex shadow-leftGrey pl-2 pr-0.5 h-full items-center"
                    onClick={async () => {
                      await acceptHandler();
                    }}
                  >
                    <RoundedTick active={false} />
                  </div>
                )}
                {status !== "rejected" && (
                  <div
                    className="cursor-pointer hidden group-hover:flex px-2 h-full items-center shadow-leftGrey"
                    onClick={async () => {
                      await rejectHandler();
                    }}
                  >
                    <RoundedCancel active={false} />
                  </div>
                )}
                {reason && (
                  <div className="cursor-pointer hidden group-hover:flex px-2.5 h-full items-center shadow-leftGrey">
                    <ChatBubble />
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {isExpanded && (
          <div className="flex-grow flex flex-row-reverse ">
            <div className="pr-2 pl-3 shadow-leftGrey flex items-center h-full">
              <div
                className="cursor-pointer"
                onClick={() => setIsExpanded(false)}
              >
                <RoundedCancel active={false} />
              </div>
            </div>
          </div>
        )}
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            key="user-request"
            initial={{
              y: !reason ? -100 : -200,
            }}
            animate={{ y: 0 }}
            exit={{ y: 0 }}
            transition={{ type: "interia", duration: reason ? 0.8 : 0.4 }}
            className={`rounded-b-xl ${
              reason ? "p-5" : "pb-5 pt-2 px-5"
            } bg-white`}
          >
            {reason && (
              <div className="bg-gray6 rounded-md p-3 font-medium">
                {reason}
              </div>
            )}
            <div className="flex justify-between mt-4">
              <button
                className={`${
                  status === "rejected" ? "bg-gray2" : "bg-red2"
                } text-white px-4 py-1 rounded-lg font-medium text-sm`}
                onClick={async () => {
                  await rejectHandler();
                }}
                disabled={status === "rejected"}
              >
                reject
              </button>
              <button
                className={`${
                  status === "completed" ? "bg-gray2" : "bg-green5"
                } text-white px-4 py-1 rounded-lg font-medium text-sm`}
                onClick={async () => {
                  await acceptHandler();
                }}
                disabled={status === "completed"}
              >
                accept
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserRequestCard;
