import React from "react"
import Button from "../Button/index"
import Typography from "../Typography/index"
import { GrClose, GrCheckmark } from "react-icons/gr"
import { HiCheck } from "react-icons/hi"
import { useModal } from "../../hooks/useModal"
import { MutatingDots } from "react-loader-spinner"

interface SuccessModalProps {
  isLoading: boolean
  title: string
  message: string
}
const SuccessModal = (props: SuccessModalProps) => {
  const { isLoading, title, message } = props

  const { closeModal } = useModal()

  return (
    <div className="bg-white outer-shadow w-full max-w-sm px-8 py-6 mx-auto rounded-xl">
      <Button
        className="ml-auto !w-fit px-0 h-fit bg-transparent mb-8"
        onClick={closeModal}
      >
        <GrClose className="text-black2" size={25} />
      </Button>
      <div className="w-fit p-8 bg-secondary rounded-full mx-auto">
        {isLoading ? (
          <MutatingDots
            height={130}
            width="100"
            color="#67823A"
            secondaryColor="#67823A"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <HiCheck className="!text-primary" size={60} />
        )}
      </div>
      <Typography
        tag="div"
        className="font-semibold text-2xl text-black2 text-center mt-3 mb-2"
      >
        {title}
      </Typography>
      <Typography
        tag="div"
        className="font-semibold text-base text-black3 mb-3 text-center"
      >
        {message}
      </Typography>
    </div>
  )
}

export default SuccessModal
