import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavTab from "../NavTabs";
import { useSeniority } from "../../store/seniority.state";

export const TopBarManagement = ({
  type = "general",
}: {
  type?: "general";
}) => {
  const navigate = useNavigate();
  const { seniorities, activeId, setActiveId } = useSeniority();
  const [searchParams] = useSearchParams();

  return (
    <div className="w-full bg-gray items-center flex justify-between mb-[60px] pl-5 py-4 pr-4 mt-[15px] font-gotham">
      <div className="flex">
        <div
          className="flex items-center bg-gray2 border-[1px] border-black3 cursor-pointer rounded-lg h-[40px] w-[40px]"
          onClick={() => {
            navigate(`/dashboard`);
          }}
        >
          <IoChevronBackOutline size={20} className="text-black mx-auto" />
        </div>
        <div className="my-auto ml-3 font-medium text-base text-black2">Back To Home</div>
      </div>
      <NavTab
        tabs={seniorities}
        className={"bg-white"}
        tabType="neumorphism"
        activeId={activeId}
        onChange={(id) => {
          setActiveId(id);
        }}
      />
      <div></div>
    </div>
  );
};
