import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import DateBlock from "./DateBlock";
import { generateDates } from "../../utils/generateDates";
import { useLocation } from "../../store/location.store";
import { CarotLeft } from "../Icons/CarotLeft";
import { CarotRight } from "../Icons/CarotRight";
import { useEstimationContext } from "../../hooks/useEstimationContext";
import { useGetShifts, usePrefetchGetShifts } from "../../hooks/useGetShifts";
import { useSeniority } from "../../store/seniority.state";
import { shiftFindFn } from "../../utils/shiftFindFn";
import {
  useGetCalendarQuery,
  useGetLeavesQuery,
} from "../../store/locationApi";
import { TIME_ZONE } from "../../constants";

type DateSliderProps = {
  activeDate: Date;
  prevDate: Date;
  nextDate: Date;
  className?: string;
};

const INIT_START_INDEX = 30;
const OFFSET = 30;

const PrevArrow = ({
  className,
  style,
  onClick,
}: {
  className: any;
  style: any;
  onClick: any;
}) => {
  return (
    <div
      className={`cursor-pointer absolute top-[50%] -left-10 bg-green1 rounded-md w-fit z-10 p-1`}
      onClick={onClick}
    >
      <CarotLeft />
    </div>
  );
};

const NextArrow = ({
  className,
  style,
  onClick,
}: {
  className: any;
  style: any;
  onClick: any;
}) => {
  return (
    <div
      className={`cursor-pointer absolute top-[50%] -right-10 bg-green1 rounded-md w-fit p-1`}
      onClick={onClick}
    >
      <CarotRight />
    </div>
  );
};

const DateScroll = ({
  activeDate,
  prevDate,
  nextDate,
  className,
}: DateSliderProps) => {
  const [activeIndex, setActiveIndex] = useState(INIT_START_INDEX);
  const [dateList, _] = useState(generateDates(activeDate, OFFSET));
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();
  const navigate = useNavigate();

  const { resetStates, triggerBarReset } = useEstimationContext();

  const {
    data: leaveDates,
    isLoading: isLeaveLoading,
    isFetching: isLeaveFetching,
  } = useGetLeavesQuery(
    {
      locationId: activeLocationId,
      month: DateTime.fromJSDate(activeDate).setZone(TIME_ZONE).month,
      year: DateTime.fromJSDate(activeDate).setZone(TIME_ZONE).year,
    },
    { skip: activeLocationId === "" },
  );

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 750,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: INIT_START_INDEX,
    arrows: true,
    centerPadding: "0px",
    centerMode: true,
    swipe: false,
    variableWidth: false,
    prevArrow: (
      <PrevArrow className={undefined} style={undefined} onClick={undefined} />
    ),
    nextArrow: (
      <NextArrow className={undefined} style={undefined} onClick={undefined} />
    ),
    beforeChange: (oldIndex: number, index: number) => {
      setActiveIndex(index);
      resetStates(activeSeniorityId);
      const dateHash = window.btoa(dateList[index].getTime().toString());
      navigate(`/dashboard/${dateHash}?locationId=${activeLocationId}`);
    },
  };

  const {
    data: shiftData,
    isLoading: isShiftLoading,
    isFetching: isShiftFetching,
    refetch: refetchShifts,
  } = useGetShifts({ activeDate, prevDate, nextDate });

  const { refetch: refetchCalendar } = useGetCalendarQuery(
    {
      locationId: activeLocationId,
      month: activeDate.getMonth() + 1,
      year: activeDate.getFullYear(),
      seniority: activeSeniorityId,
    },
    { skip: !activeLocationId },
  );

  const { triggerPrefetch: triggerRefetch } = usePrefetchGetShifts({
    activeDate,
  });

  useEffect(() => {
    if (activeIndex === OFFSET * 2 - 2 || activeIndex === 0) {
      window.location.reload();
    }
  }, [activeIndex]);

  return (
    <div className={`w-[90%] h-full ${className}`}>
      <Slider {...sliderSettings}>
        {dateList.map((date, index) => (
          <DateBlock
            refetchCalendar={async () => {
              await refetchCalendar();
            }}
            key={index}
            triggerBarReset={triggerBarReset}
            isShiftsLoading={
              isShiftLoading || isShiftFetching || isLeaveLoading
            }
            date={date}
            leaveDates={leaveDates}
            shifts={
              shiftData?.find(
                shiftFindFn({
                  date,
                  activeLocationId,
                  activeSeniorityId,
                }),
              ) ?? []
            }
            refetchShifts={async () => {
              refetchShifts();
              triggerRefetch();
            }}
            type={
              index === activeIndex - 1
                ? "dayBefore"
                : index === activeIndex
                ? "inFocus"
                : index === activeIndex + 1
                ? "dayAfter"
                : "dayAfter"
            }
          />
        ))}
      </Slider>
    </div>
  );
};

export default DateScroll;
