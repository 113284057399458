import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const requestsApi = createApi({
  reducerPath: "requestsApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  endpoints: (builder) => ({
    getShiftRequests: builder.query<any, any>({
      query: ({ locationId, date, seniority }) => ({
        url: `doctor/shift-request/location/${locationId}?date=${date}&seniority=${seniority}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getDayOffRequests: builder.query<any, any>({
      query: ({ date }) => ({
        url: `doctor/day-off?date=${date}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    processShiftRequest: builder.mutation<any, any>({
      query: ({ shiftRequestId, status, shiftId }) => ({
        url: `doctor/shift-request/process/${shiftRequestId}`,
        method: "POST",
        body: {
          status,
          shiftId,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    processDayOffRequest: builder.mutation<any, any>({
      query: ({ dayOffRequestId, status }) => ({
        url: `doctor/day-off/process`,
        method: "POST",
        body: {
          status,
          dayOffRequestId,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    autoAcceptShiftRequests: builder.mutation<any, any>({
      query: ({ shiftId }) => ({
        url: `doctor/shift-request/accept/all`,
        method: "POST",
        body: {
          shiftId,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetShiftRequestsQuery,
  useProcessShiftRequestMutation,
  useGetDayOffRequestsQuery,
  useProcessDayOffRequestMutation,
  useAutoAcceptShiftRequestsMutation,
  usePrefetch,
} = requestsApi;
