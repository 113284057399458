import React from "react";

const InfoIcon = ({
  className,
  strokeClass,
}: {
  className: string;
  strokeClass: string;
}) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8690_73066)">
        <path
          d="M6.99935 12.8327C10.221 12.8327 12.8327 10.221 12.8327 6.99935C12.8327 3.77769 10.221 1.16602 6.99935 1.16602C3.77769 1.16602 1.16602 3.77769 1.16602 6.99935C1.16602 10.221 3.77769 12.8327 6.99935 12.8327Z"
          className={strokeClass}
          stroke="#BDBDBD"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 9.33333V7"
          className={strokeClass}
          stroke="#BDBDBD"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 4.66602H7.00667"
          className={strokeClass}
          stroke="#BDBDBD"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8690_73066">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIcon;
