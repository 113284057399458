import { useCallback, useEffect, useRef, useState } from "react";
import { DATE_RANGE, TIME_ZONE } from "../constants";
import { useLocation } from "../store/location.store";
import { useSeniority } from "../store/seniority.state";
import { useGetShiftsQuery, usePrefetch } from "../store/shiftsApi";
import { formatDateAPI } from "../utils/formatTime";
import { DateTime } from "luxon";

export const sortShifts = (
  shiftA: {
    type: "onCall" | "standBy" | "normal";
    time: { from: string; to: string };
  },
  shiftB: {
    type: "onCall" | "standBy" | "normal";
    time: { from: string; to: string };
  },
) => {
  if (shiftA.type === shiftB.type) {
    if (
      DateTime.fromISO(shiftA.time.from)
        .setZone(TIME_ZONE)
        .equals(DateTime.fromISO(shiftB.time.from).setZone(TIME_ZONE))
    ) {
      return (
        DateTime.fromISO(shiftA.time.to)
          .setZone(TIME_ZONE)
          .toJSDate()
          .getTime() -
        DateTime.fromISO(shiftB.time.to).setZone(TIME_ZONE).toJSDate().getTime()
      );
    }
    return (
      DateTime.fromISO(shiftA.time.from)
        .setZone(TIME_ZONE)
        .toJSDate()
        .getTime() -
      DateTime.fromISO(shiftB.time.from).setZone(TIME_ZONE).toJSDate().getTime()
    );
  }
  if (shiftA.type === "normal") {
    return -1;
  }
  if (shiftB.type === "normal") {
    return 1;
  }
  if (shiftA.type === "standBy" && shiftB.type === "onCall") {
    return -1;
  }
  if (shiftA.type === "onCall" && shiftB.type === "standBy") {
    return 1;
  }
  return -1;
};

// Change to a range
export const useGetShifts = ({
  activeDate,
  prevDate,
  nextDate,
}: {
  activeDate: Date;
  prevDate: Date;
  nextDate: Date;
}) => {
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniority } = useSeniority();

  const prevDateObj = useGetShiftsQuery(
    {
      locationId: activeLocationId,
      date: formatDateAPI(prevDate),
      seniority: activeSeniority,
      range: DATE_RANGE,
    },
    { skip: !activeLocationId },
  );

  const activeDateObj = useGetShiftsQuery(
    {
      locationId: activeLocationId,
      date: formatDateAPI(activeDate),
      seniority: activeSeniority,
      range: DATE_RANGE,
    },
    { skip: !activeLocationId },
  );

  const nextDateObj = useGetShiftsQuery(
    {
      locationId: activeLocationId,
      date: formatDateAPI(nextDate),
      seniority: activeSeniority,
      range: DATE_RANGE,
    },
    { skip: !activeLocationId },
  );

  return {
    data:
      prevDateObj.data && activeDateObj.data && nextDateObj.data
        ? [
            [...prevDateObj.data].sort(sortShifts),
            [...activeDateObj.data].sort(sortShifts),
            [...nextDateObj.data].sort(sortShifts),
          ]
        : undefined,
    isLoading:
      prevDateObj.isLoading || activeDateObj.isLoading || nextDateObj.isLoading,
    isFetching:
      prevDateObj.isFetching ||
      activeDateObj.isFetching ||
      nextDateObj.isFetching,
    refetch: () => {
      prevDateObj.refetch();
      activeDateObj.refetch();
      nextDateObj.refetch();
    },
  };
};

export const useGetShiftsSingle = ({ activeDate }: { activeDate: Date }) => {
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniority } = useSeniority();

  return useGetShiftsQuery(
    {
      locationId: activeLocationId,
      date: formatDateAPI(activeDate),
      seniority: activeSeniority,
      range: DATE_RANGE,
    },
    { skip: !activeLocationId },
  );
};

export const usePrefetchGetShifts = ({ activeDate }: { activeDate: Date }) => {
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniority } = useSeniority();

  const prefetchGetShifts = usePrefetch("getShifts");

  const [trigger, setTrigger] = useState(false);
  const callbackRef = useRef<((force: boolean) => void) | null>(null);

  callbackRef.current = useCallback(
    (force: boolean) => {
      if (activeLocationId) {
        [-3, -2, 2, 3]
          .map((delta) =>
            DateTime.fromJSDate(activeDate)
              .setZone(TIME_ZONE)
              .plus({ day: delta })
              .toJSDate(),
          )
          .forEach((date) => {
            prefetchGetShifts(
              {
                locationId: activeLocationId,
                date: formatDateAPI(date),
                seniority: activeSeniority,
                range: DATE_RANGE,
              },
              { force },
            );
          });
      }
    },
    [activeDate, activeLocationId, activeSeniority, prefetchGetShifts],
  );

  useEffect(() => {
    callbackRef.current?.(false);
  }, [activeDate, activeLocationId, activeSeniority]);

  useEffect(() => {
    callbackRef.current?.(true);
  }, [trigger]);

  return {
    triggerPrefetch: () => {
      setTrigger((trigger) => !trigger);
    },
  };
};
