import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavTab from "../NavTabs";
import { useSeniority } from "../../store/seniority.state";
import { useEstimationContext } from "../../hooks/useEstimationContext";

export const TopBar = ({
  type = "general",
}: {
  type?: "estimation" | "general";
}) => {
  const navigate = useNavigate();
  const { seniorities, activeId, setActiveId } = useSeniority();
  const { resetStates } = useEstimationContext();
  const [searchParams] = useSearchParams();

  return (
    <div className="w-full bg-white items-center flex justify-between px-10 mb-[60px] fixed z-[100]">
      <div
        className="flex items-center bg-gray2 border-gray cursor-pointer rounded h-[25px] w-[25px]"
        onClick={() => {
          navigate(`/dashboard?locationId=${searchParams.get("locationId")}`);
        }}
      >
        <IoChevronBackOutline size={20} className="text-black mx-auto" />
      </div>
      <NavTab
        tabs={seniorities}
        tabType="neumorphism"
        activeId={activeId}
        onChange={(id) => {
          setActiveId(id);
          if (type === "estimation") {
            resetStates(id);
          }
        }}
      />
      <div></div>
    </div>
  );
};
