const ChatBubble = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        d="M8.26748 12.1861C10.7076 12.0239 12.6512 10.0527 12.8112 7.578C12.8425 7.09372 12.8425 6.59217 12.8112 6.10789C12.6512 3.6332 10.7076 1.66197 8.26748 1.49977C7.43501 1.44444 6.5659 1.44455 5.73512 1.49977C3.29503 1.66197 1.35137 3.6332 1.19144 6.10789C1.16014 6.59217 1.16014 7.09372 1.19144 7.578C1.24969 8.47931 1.6483 9.31383 2.11758 10.0185C2.39006 10.5118 2.21023 11.1275 1.92642 11.6654C1.72179 12.0532 1.61947 12.2471 1.70162 12.3871C1.78378 12.5272 1.96729 12.5317 2.3343 12.5406C3.06011 12.5583 3.54953 12.3525 3.93803 12.066C4.15837 11.9035 4.26854 11.8223 4.34447 11.8129C4.4204 11.8036 4.56983 11.8652 4.86864 11.9882C5.1372 12.0988 5.44903 12.1671 5.73512 12.1861C6.5659 12.2413 7.43501 12.2415 8.26748 12.1861Z"
        stroke="#4F4F4F"
        stroke-linejoin="round"
      />
      <path
        d="M6.9987 7H7.00389M9.32941 7H9.33466M4.66797 7H4.6732"
        stroke="#4F4F4F"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatBubble