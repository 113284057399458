import { useState } from "react";
import Button from "../Button/index";
import Input from "../Input/index";
import Typography from "../Typography/index";
import { useModal } from "../../hooks/useModal";
import api from "../../store/api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { z } from "zod";
import TagInput from "../TagInput/TagInput";
import NavTab from "../NavTabs";
import { useSeniority } from "../../store/seniority.state";
import Slider from "react-rangeslider";
import { useUserSettingsStore } from "../../store/userSettings.state";

interface EditModalProps {
  onSubmit?: (doctor: any) => void;
  doctor: any;
}

export interface EditDoctorForm {
  doctorId: string;
  name: string;
  experience: number;
  seniority: number;
  efficiencyScore: number;
  tags: string[];
}

const EditDoctor = (props: EditModalProps) => {
  const { onSubmit, doctor } = props;
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const { seniorities } = useSeniority();
  const [selectedSeniority, setSelectedSeniority] = useState<number>(
    doctor.seniority.id,
  );
  const settings = useUserSettingsStore();

  const { closeModal } = useModal();

  const formik = useFormik<EditDoctorForm>({
    initialValues: {
      doctorId: doctor._id,
      name: doctor.user.name,
      experience: doctor.experience,
      seniority: doctor.seniority.id,
      efficiencyScore: doctor.efficiencyScore,
      tags: doctor.tags ?? [],
    },
    validate: (values) => {
      const schema = z.object({
        doctorId: z.string().nonempty("Doctor id is required"),
        name: z.string().nonempty("Name is required"),
        experience: z
          .number({
            required_error: "Experience is required",
            invalid_type_error: "Experience must be a number",
          })
          .min(0)
          .max(10),
        seniority: z.number({ required_error: "Seniority is required" }),
        efficiencyScore: z
          .number({
            required_error: "Efficiency score is required",
            invalid_type_error: "Efficiency score must be a number",
          })
          .min(0)
          .max(10),
        tags: z.array(z.string()).default([]),
      });

      const result = schema.safeParse(values);
      if (!result.success) {
        return Object.fromEntries(
          result.error.errors.map((err) => [err.path[0], err.message]),
        );
      }
      return {};
    },
    onSubmit: async (values) => {
      const addDoc = await updateDoctor({
        data: {
          ...values,
        },
      });
      const { isSuccess, data, isError, error } = addDoc.unwrap();
      if (isSuccess) {
        toast.success("Create doctor successfully");
        onSubmit?.(data);
        closeModal();
      }

      if (isError) {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      }
    },
  });

  const { fetchFn: updateDoctor, isLoading } = api.doctor.useUpdateDoctor();

  return (
    <form
      onSubmit={formik.handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
        if (e.key === "Escape") {
          closeModal();
        }
      }}
      className="outer-shadow bg-white w-full max-w-2xl px-8 py-6 mx-auto rounded-xl"
    >
      <div className="grid grid-cols-2 gap-x-8 gap-4 mb-8">
        <div className="col-span-2">
          <div className="flex items-center gap-4">
            <input
              type={"radio"}
              name="default-radio"
              checked={!showUpload}
              onChange={(e) => setShowUpload(!e.target.checked)}
              className="!w-4 !h-4 !text-secondary bg-gray-100 border-gray-300 "
            />
            <Typography
              tag="div"
              className="font-semibold text-lg text-black2 "
            >
              Add User
            </Typography>
          </div>
        </div>
        <div className="col-span-1 grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Doctor Name
            </Typography>
            <Input
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Name"
              className="!text-base !placeholder:text-sm"
              errorMsg={formik.touched.name ? formik.errors.name : ""}
            />
          </div>
          {settings.experience ? (
            <div className="col-span-2">
              <Typography
                tag="div"
                className="font-semibold text-base text-black3 mb-2"
              >
                Experience ({formik.values.experience} years)
              </Typography>
              <Slider
                min={0}
                max={10}
                value={formik.values.experience}
                onChange={(val) => {
                  formik.setFieldValue("experience", val);
                }}
              />
            </div>
          ) : null}
        </div>
        {settings.efficiency ? (
          <div className="col-span-1 col-start-2">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Efficiency Score ({formik.values.efficiencyScore} out of 10)
            </Typography>
            <Slider
              min={0}
              max={10}
              value={formik.values.efficiencyScore}
              onChange={(val) => {
                formik.setFieldValue("efficiencyScore", val);
              }}
            />
          </div>
        ) : null}

        <div className="col-span-2">
          <NavTab
            tabs={seniorities}
            tabType="neumorphism"
            activeId={selectedSeniority}
            onChange={(id) => {
              setSelectedSeniority(id);
              formik.setFieldValue("seniority", id);
            }}
          />
        </div>

        <div className="col-span-2">
          <Typography
            tag="div"
            className="font-semibold text-base text-black3 mb-2"
          >
            Tags
          </Typography>
          <TagInput
            onChange={(tags) => formik.setFieldValue("tags", tags)}
            initialTags={doctor.tags}
          />
        </div>
      </div>
      <div className="flex items-center justify-center gap-8">
        <Button
          disabled={isLoading}
          className="flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl h-10"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          type="submit"
          className="flex justify-center text-center !rounded-xl"
        >
          {isLoading ? "Saving..." : "Save"}
        </Button>
      </div>
    </form>
  );
};

export default EditDoctor;
