import { FC, useState } from "react";
import { motion } from "framer-motion";
import { InfinitySpin } from "react-loader-spinner";

import HorizontalScrollContainer from "../HorizontalScrollContainer";
import ShiftPill from "./ShiftPill";
import UserRequestCard from "./UserRequestCard";
import { formatTime } from "../../utils/formatTime";
import { Shift } from "../../interface/shift";
import Grid from "../Icons/Grid";

import {
  useAutoAcceptShiftRequestsMutation,
  useProcessDayOffRequestMutation,
  useProcessShiftRequestMutation,
} from "../../store/requestsApi";
import useHandleSuccessErrors from "../../hooks/handleSuccessErrors";
import useRunOnChange from "../../hooks/runOnChange";
import { useSeniority } from "../../store/seniority.state";
import { useLocation } from "../../store/location.store";
import { checkContested, shiftRequestFilter } from "../../utils/shiftRequests";
import useRunOnce from "../../hooks/runOnce";
import { useEstimationContext } from "../../hooks/useEstimationContext";

interface RequestPhaseWindowProps {
  shifts?: Array<Shift>;
  shiftRequests?: {
    requestedBy: { user: { name: string } };
    shifts: Shift[];
    _id: string;
    name: string;
    time: string;
    reason?: string;
    completedShift?: Shift;
    status: "completed" | "pending" | "rejected";
  }[];
  dayOffRequests?: any[];
  dayOff?: { doctors: any[] };
  isLoading: boolean;
  isFetching: boolean;
  refetchShiftRequests: () => Promise<void>;
  refetchDayOffRequests: () => Promise<void>;
  refetchShifts: () => Promise<void>;
  refetchStats: () => Promise<void>;
  refetchDoctors: () => Promise<void>;
  refetchDayOffs: () => Promise<void>;
  deadline: string | undefined;
}

const RequestPhaseWindow: FC<RequestPhaseWindowProps> = ({
  shifts,
  shiftRequests,
  dayOffRequests,
  dayOff,
  isLoading,
  isFetching,
  refetchDayOffRequests,
  refetchShiftRequests,
  refetchShifts,
  refetchStats,
  refetchDoctors,
  refetchDayOffs,
}) => {
  const { activeId: activeSeniorityId } = useSeniority();
  const { activeId: activeLocationId } = useLocation();

  const { requestTab, setRequestTab } = useEstimationContext();

  useRunOnChange(shifts?.[0]?._id, shifts?.[0]?._id, () => {
    setRequestTab(shifts?.[0]?._id ?? "dayOff");
  });

  const [
    processShiftRequest,
    { isLoading: isProcessShiftRequestLoading, isSuccess, isError, error },
  ] = useProcessShiftRequestMutation();

  const [
    processDayOffRequest,
    {
      isLoading: isDayOffLoading,
      isSuccess: isDayOffSuccess,
      isError: isDayOffError,
      error: dayOffError,
    },
  ] = useProcessDayOffRequestMutation();

  const [
    autoAcceptShiftRequests,
    {
      isLoading: isAutoAcceptLoading,
      isSuccess: isAutoAcceptSuccess,
      isError: isAutoAcceptError,
      error: autoAcceptError,
    },
  ] = useAutoAcceptShiftRequestsMutation();

  useHandleSuccessErrors({
    isSuccess: isAutoAcceptSuccess,
    isError: isAutoAcceptError,
    error: autoAcceptError,
    successMessage: "Shift Requests has been processed",
    successFunction: async () => {
      await refetchShiftRequests();
      await refetchShifts();
      await refetchStats();
      await refetchDoctors();
    },
  });

  useHandleSuccessErrors({
    isSuccess,
    isError,
    error,
    successMessage: "Shift Request has been processed",
    successFunction: async () => {
      await refetchShiftRequests();
      await refetchShifts();
      await refetchStats();
      await refetchDoctors();
    },
  });

  useHandleSuccessErrors({
    isSuccess: isDayOffSuccess,
    isError: isDayOffError,
    error: dayOffError,
    successMessage: "Day Off Request has been processed",
    successFunction: async () => {
      await refetchDayOffRequests();
      await refetchStats();
      await refetchDoctors();
      await refetchDayOffs();
    },
  });

  const selectedShiftRequests =
    shiftRequests?.filter((sR) =>
      sR.shifts.map((shift) => shift._id).includes(requestTab ?? ""),
    ) ?? [];

  const shouldAllowAutoAccept = (() => {
    const pendingSelectedShiftRequests = selectedShiftRequests.filter(
      (sR) => sR.status === "pending",
    );

    if (pendingSelectedShiftRequests.length === 0) {
      return false;
    }

    if (requestTab !== "dayOff") {
      return !checkContested(pendingSelectedShiftRequests);
    }

    return false;
  })();

  return (
    <motion.div
      key="request-phase-window"
      className="relative w-full h-full transition duration-500"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.8 }}
    >
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <InfinitySpin width="200" color="#67823A" />
        </div>
      ) : (
        <div className="w-full h-full">
          <div className="flex justify-between">
            <HorizontalScrollContainer className="w-full">
              {[
                ...(shifts?.map((val) => ({
                  name: `${formatTime(val.time.from)} - ${formatTime(
                    val.time.to,
                  )}${
                    val.type !== "normal"
                      ? ` (${val.type === "onCall" ? "On Call" : "Stand By"})`
                      : ""
                  }`,
                  key: val._id,
                  doctorsRequired: val.totalDoctorsRequired,
                  doctorsFilled:
                    val.doctorsFilled +
                    (shiftRequests?.filter(
                      (sR) =>
                        sR.shifts.map((shift) => shift._id).includes(val._id) &&
                        sR.status === "pending",
                    ).length ?? 0),
                })) ?? []),
                {
                  name: "Day Off",
                  key: "dayOff",
                  doctorsRequired: 10,
                  doctorsFilled:
                    (dayOffRequests?.filter((dR) => dR.status === "pending")
                      .length ?? 0) + (dayOff?.doctors.length ?? 0),
                },
              ].map((pill) => {
                const currentShiftRequests =
                  shiftRequests?.filter(shiftRequestFilter(pill.key)) ?? [];

                const isContested = (() => {
                  if (pill.key === "dayOff") {
                    return pill.doctorsFilled >= pill.doctorsRequired;
                  } else {
                    return checkContested(currentShiftRequests);
                  }
                })();

                return (
                  <ShiftPill
                    onClick={() => setRequestTab(pill.key)}
                    className="flex w-fit justify-between transition-colors relative z-10"
                    isActive={requestTab === pill.key}
                    isActiveClassName={`${
                      isContested ? "bg-maroon2" : "bg-teal3"
                    } border border-teal2 text-white`}
                    key={pill.key}
                  >
                    {pill.name}
                    {requestTab === pill.key ? (
                      <div
                        className={`bg-white ml-4 ${
                          isContested ? "text-maroon2" : "text-teal3"
                        } rounded-md px-1`}
                      >
                        {pill.doctorsFilled}/{pill.doctorsRequired}
                      </div>
                    ) : (
                      <></>
                    )}
                  </ShiftPill>
                );
              })}
            </HorizontalScrollContainer>
            <div
              className={` px-2 py-1 rounded-lg ${
                shouldAllowAutoAccept ? "cursor-pointer bg-white" : "bg-gray2"
              } relative z-10`}
              onClick={async () => {
                if (shouldAllowAutoAccept) {
                  await autoAcceptShiftRequests({ shiftId: requestTab });
                }
              }}
            >
              <Grid
                className={isAutoAcceptLoading ? "animate-spin" : ""}
                fill={"#838383"}
                width="23"
                height="23"
              />
            </div>
          </div>
          {selectedShiftRequests.length > 0 ? (
            <div className="grid grid-cols-2 mt-8 gap-x-10 gap-y-2">
              {selectedShiftRequests.map((sR) => (
                <UserRequestCard
                  key={sR._id}
                  seniority={activeSeniorityId}
                  reason={sR.reason}
                  name={sR.requestedBy.user.name}
                  status={sR.status}
                  locationLabel={sR?.completedShift?.location.label}
                  isDisabled={
                    sR?.completedShift
                      ? sR.completedShift.location._id !== activeLocationId
                      : false
                  }
                  acceptHandler={async () => {
                    await processShiftRequest({
                      shiftRequestId: sR._id,
                      status: "completed",
                      shiftId: requestTab,
                    });
                  }}
                  rejectHandler={async () => {
                    await processShiftRequest({
                      shiftRequestId: sR._id,
                      status: "rejected",
                      shiftId: requestTab,
                    });
                  }}
                  isLoading={isProcessShiftRequestLoading || isFetching}
                />
              ))}
            </div>
          ) : requestTab !== "dayOff" ? (
            <div className="w-full h-full flex justify-center items-center font-medium">
              No shift requests sent
            </div>
          ) : (
            <div className="grid grid-cols-2 mt-8 gap-x-10 gap-y-2">
              {dayOffRequests?.map((dR) => (
                <UserRequestCard
                  seniority={activeSeniorityId}
                  name={dR.requestedBy.user.name}
                  status={dR.status}
                  acceptHandler={async () => {
                    await processDayOffRequest({
                      dayOffRequestId: dR._id,
                      status: "completed",
                    });
                  }}
                  rejectHandler={async () => {
                    await processDayOffRequest({
                      dayOffRequestId: dR._id,
                      status: "rejected",
                    });
                  }}
                  isLoading={isDayOffLoading || isFetching}
                />
              )) ?? []}
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
};

export default RequestPhaseWindow;
