import { useEffect, useState } from "react"
import Input from "../Input/index"
import TagPill from "./TagPill"

interface TagInputProps {
  initialTags?: any[]
  onChange?: (tags: string[]) => void
}

const TagInput = (props: TagInputProps) => {
  const { initialTags, onChange } = props
  const [tags, setTags] = useState<string[]>(initialTags?.map(tag => tag.value) ?? [])
  const [input, setInput] = useState<string>("")

  useEffect(() => {
    onChange?.(tags)
  }, [tags])

  function handleInput(e: React.ChangeEvent<HTMLInputElement>) {
    console.log("input", e.target.value)
    setInput(e.target.value)
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && input !== "") {
      setInput("")
      if (tags.includes(input)) return
      setTags([...tags, input])
    }
  }

  const handleRemoTags = (index: number) => {
    const newTags = [...tags]
    newTags.splice(index, 1)
    setTags(newTags)
  }

  return (
    <div className="outer-shadow w-full max-w-[50rem] px-8 py-6 mx-auto rounded-xl">
      <div className="">
        <Input
          placeholder="Enter New Tag"
          value={input}
          onKeyDown={(e: React.KeyboardEvent) => handleKeyPress(e)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
        />
      </div>
      <div className="my-4">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="inline-block bg-gray-200 rounded-full px-3 py-1.5 mb-4 text-sm font-semibold text-gray-700 mr-2"
          >
            <TagPill tag={tag} index={index} onDelete={handleRemoTags} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default TagInput
